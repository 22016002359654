import React from "react";
import "./Tabs.scss";
import Tab from "./Tab";

const Tabs = ({ tabsData, tabClickHandler, activeTab }) => {
  return (
    <div className="matches_tab_cont">
      {tabsData?.map((each) => (
        <Tab
          label={each?.label}
          isActive={activeTab?.key == each?.key}
          tabClickHandler={tabClickHandler}
          data={each}
          sub_label={each.sub_label}
        />
      ))}
    </div>
  );
};

export default Tabs;
