import React from "react";
import { AiFillHome } from "react-icons/ai";
import { FaListUl } from "react-icons/fa";
import { BsFillLightbulbFill } from "react-icons/bs";
import { BiSolidUserCircle } from "react-icons/bi";
import "./Navbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { MdSportsCricket } from "react-icons/md";


const navData = [
  {
    icon: <AiFillHome />,
    text: "Home",
    active: true,
    route: "/",
  },
  {
    icon: <MdSportsCricket /> ,
    text: "Score",
    active: false,
    route: "/matches",
  },
  {
    icon: <FaListUl />,
    text: "Listing",
    active: false,
    route: "/casino-brands",
  },
  {
    icon: <BsFillLightbulbFill />,
    text: "Articles",
    active: false,
    route: "/blogs?category=11",
    pathname: "/blogs",
  },
  {
    icon: <BiSolidUserCircle />,
    text: "Profile",
    active: false,
    route: "/profile",
  },
];



export default function Navbar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navClickHandler = (route) => {
    navigate(route);
  };
  if (pathname == "/matches"||pathname?.includes("match-info")) {
    return null;
  }
  console.log(pathname,'some path')
  return (
    <div className={`navigation-bar ${pathname=="/"&&"home "}`}>
      {navData.map((data) => (
        <div
          onClick={() => navClickHandler(data.route)}
          className={` elements${
            ((pathname == data.route || pathname == data.pathname) &&
              " active") ||
            ""
          }`}
        >
          <div className="icon">{data.icon}</div>
          <div className="text">{data.text}</div>
        </div>
      ))}
    </div>
  );
}
