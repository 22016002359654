import React from "react";

const Tab = ({ data, label, sub_label, isActive, tabClickHandler }) => {
  return (
    <div
      onClick={() => tabClickHandler(data)}
      className={`matches_tab ${isActive && " is_active"}`}
    >
      <p>{label}</p>
      {sub_label && <p className="sub_label">{sub_label}</p>}
    </div>
  );
};

export default Tab;
