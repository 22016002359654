export const liveData = {
    series_name: "WBBL 2023",
    match_count: "39th T20",
    series_image: "https://picsum.photos/200/300",
    match_starts_at: "15 Nov , 06:30 AM",
    location: "Allan Border Field, Brisbane , Australia",
    streaming_at: "FanCode , Star Sports Network",
    team_a_title: "Brisbane Heat Women",
    team_a_logo: "https://picsum.photos/100/100",
    team_b_title: "Hobart Hurricanes Women",
    team_b_logo: "https://picsum.photos/101/101",
    team_a_players: [
        {
            player_name: "E Villani (c)",
            specification: "RH Bat",
            image: "https://picsum.photos/200/200"
        },
        {
            player_name: "E Villani (wk)",
            specification: "RH Bat",
            image: "https://picsum.photos/201/201",
            is_outer_country: true
        },
        {
            player_name: "B Smith",
            specification: "All Rounder",
            image: "https://picsum.photos/202/202",
            is_outer_country: true
        },
        {
            player_name: "G Graham",
            specification: "All Rounder",
            image: "https://picsum.photos/202/202",
        },
        {
            player_name: "G Graham",
            specification: "All Rounder",
            image: "https://picsum.photos/202/202",
        },
        {
            player_name: "G Graham",
            specification: "All Rounder",
            image: "https://picsum.photos/202/202",
        },
        {
            player_name: "G Graham",
            specification: "All Rounder",
            image: "https://picsum.photos/202/202",
        },
    ],
    team_b_players: [
        {
            player_name: "M du Preez (c)",
            specification: "RH Bat",
            image: "https://picsum.photos/200/200"
        },
        {
            player_name: "E Villani (wk)",
            specification: "RH Bat",
            image: "https://picsum.photos/201/201",
            is_outer_country: true
        },
        {
            player_name: "B Smith",
            specification: "All Rounder",
            image: "https://picsum.photos/202/202",
            is_outer_country: true
        },
        {
            player_name: "G Graham",
            specification: "All Rounder",
            image: "https://picsum.photos/202/202",
        },
        {
            player_name: "G Graham",
            specification: "All Rounder",
            image: "https://picsum.photos/202/202",
        },
        {
            player_name: "G Graham",
            specification: "All Rounder",
            image: "https://picsum.photos/202/202",
        },
        {
            player_name: "G Graham",
            specification: "All Rounder",
            image: "https://picsum.photos/202/202",
        },
    ]
}

export const team_a_form = {
    logo: "https://picsum.photos/100/100",
    short_name: "BHW",
    last_5_matches: [
        {
            is_win: true,
            is_loose: false,
            is_draw: false,
            series_name: "WBBL 2023",
            match_count: "39th T20",
            match_starts_at: "15 Nov , 06:30 AM",
            team_a_info: {
                image: "https://picsum.photos/200/190",
                short_name: "ADSW",
                score: 137,
                wickets: 4,
                overs: 20
            },
            team_b_info: {
                image: "https://picsum.photos/200/190",
                short_name: "BHW",
                score: 137,
                wickets: 4,
                overs: 20
            }
        },
        {
            is_win: false,
            is_loose: true,
            is_draw: false,
            series_name: "WBBL 2023",
            match_count: "39th T20",
            match_starts_at: "15 Nov , 06:30 AM",
            team_a_info: {
                image: "https://picsum.photos/200/190",
                short_name: "ADSW",
                score: 137,
                wickets: 4,
                overs: 20
            },
            team_b_info: {
                image: "https://picsum.photos/200/190",
                short_name: "BHW",
                score: 137,
                wickets: 4,
                overs: 20
            }
        },
        {
            is_win: true,
            is_loose: false,
            is_draw: false,
            series_name: "WBBL 2023",
            match_count: "39th T20",
            match_starts_at: "15 Nov , 06:30 AM",
            team_a_info: {
                image: "https://picsum.photos/200/190",
                short_name: "ADSW",
                score: 137,
                wickets: 4,
                overs: 20
            },
            team_b_info: {
                image: "https://picsum.photos/200/190",
                short_name: "BHW",
                score: 137,
                wickets: 4,
                overs: 20
            }
        },
        {
            is_win: false,
            is_loose: true,
            is_draw: false,
            series_name: "WBBL 2023",
            match_count: "39th T20",
            match_starts_at: "15 Nov , 06:30 AM",
            team_a_info: {
                image: "https://picsum.photos/200/190",
                short_name: "ADSW",
                score: 137,
                wickets: 4,
                overs: 20
            },
            team_b_info: {
                image: "https://picsum.photos/200/190",
                short_name: "BHW",
                score: 137,
                wickets: 4,
                overs: 20
            }
        },
        {
            is_win: false,
            is_loose: true,
            is_draw: false,
            series_name: "WBBL 2023",
            match_count: "39th T20",
            match_starts_at: "15 Nov , 06:30 AM",
            team_a_info: {
                image: "https://picsum.photos/200/190",
                short_name: "ADSW",
                score: 137,
                wickets: 4,
                overs: 20
            },
            team_b_info: {
                image: "https://picsum.photos/200/190",
                short_name: "BHW",
                score: 137,
                wickets: 4,
                overs: 20
            }
        },
    ]
}
export const team_b_form = {
    logo: "https://picsum.photos/99/99",
    short_name: "WBBL",
    last_5_matches: [
        {
            is_win: true,
            is_loose: false,
            is_draw: false,
            series_name: "WBBL 2023",
            match_count: "39th T20",
            match_starts_at: "15 Nov , 06:30 AM",
            team_a_info: {
                image: "https://picsum.photos/200/190",
                short_name: "HBHW",
                score: 137,
                wickets: 4,
                overs: 20
            },
            team_b_info: {
                image: "https://picsum.photos/200/190",
                short_name: "BHW",
                score: 137,
                wickets: 4,
                overs: 20
            }
        },
        {
            is_win: false,
            is_loose: true,
            is_draw: false,
            series_name: "WBBL 2023",
            match_count: "39th T20",
            match_starts_at: "15 Nov , 06:30 AM",
            team_a_info: {
                image: "https://picsum.photos/200/190",
                short_name: "ADSW",
                score: 137,
                wickets: 4,
                overs: 20
            },
            team_b_info: {
                image: "https://picsum.photos/200/190",
                short_name: "BHW",
                score: 137,
                wickets: 4,
                overs: 20
            }
        },
        {
            is_win: true,
            is_loose: false,
            is_draw: false,
            series_name: "WBBL 2023",
            match_count: "39th T20",
            match_starts_at: "15 Nov , 06:30 AM",
            team_a_info: {
                image: "https://picsum.photos/200/190",
                short_name: "HBHW",
                score: 137,
                wickets: 4,
                overs: 20
            },
            team_b_info: {
                image: "https://picsum.photos/200/190",
                short_name: "BHW",
                score: 137,
                wickets: 4,
                overs: 20
            }
        },
        {
            is_win: false,
            is_loose: true,
            is_draw: false,
            series_name: "WBBL 2023",
            match_count: "39th T20",
            match_starts_at: "15 Nov , 06:30 AM",
            team_a_info: {
                image: "https://picsum.photos/200/190",
                short_name: "ADSW",
                score: 137,
                wickets: 4,
                overs: 20
            },
            team_b_info: {
                image: "https://picsum.photos/200/190",
                short_name: "BHW",
                score: 137,
                wickets: 4,
                overs: 20
            }
        },
        {
            is_win: false,
            is_loose: true,
            is_draw: false,
            series_name: "WBBL 2023",
            match_count: "39th T20",
            match_starts_at: "15 Nov , 06:30 AM",
            team_a_info: {
                image: "https://picsum.photos/200/190",
                short_name: "ADSW",
                score: 137,
                wickets: 4,
                overs: 20
            },
            team_b_info: {
                image: "https://picsum.photos/200/190",
                short_name: "BHW",
                score: 137,
                wickets: 4,
                overs: 20
            }
        },
    ]
}


export const keys = {
    h2h : "Head to Head (last 10 matches)",
    sqad : "Playing XI",
    team_form : "Team Form",
    umpires : "Umpires",
    date_location : "date & location",
    match : "match",
    point_table : "point_table",
    teams_with_logo : "team(for image and name)",
    team_comparison : "team_comparison",
    toss : "toss"
}