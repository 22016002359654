import React, { useEffect, useState } from "react";import axios from "axios";
import parse from "html-react-parser";
import CustomImage from "../../Components/CustomImage/CustomImage";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./Post.css";

export default function ViewPost() {
	const { id: currentPostId } = useParams();
	const [loading, setLoading] = useState(false);
	const [currentPost, setCurrentPost] = useState([]);

	const fetchPosts = () => {
		setLoading(true);
		axios.get("/post")
			.then((res) => {
				setLoading(false);
				let temppost;
				res.data?.data?.data?.forEach((data) => {
					if (data.id == currentPostId) {
						temppost = data;
					}
				});
				console.log(temppost);
				temppost && setCurrentPost(temppost);
			})
			.catch((e) => {
				setLoading(false);
				toast.error("Something went wrong try again...");
			});
	};

	useEffect(() => {
		fetchPosts();
	}, []);


	if (loading) {
		return <> </>;
	} else {
		return (
			<div className="post">
				<h3>{currentPost.title}</h3>
				<div className="news_image">
					<CustomImage src={currentPost.image || currentPost.filename} />
				</div>
				<div className="content">
					<p className="description_detail">
						{currentPost.description}
					</p>
					{parse(currentPost.html || "")}
				</div>
			</div>
		);
	}
}
