import React, { useEffect, useMemo, useState } from "react";
import "./ScoreCardPage.scss";
import Table from "./Table";
import {
  fallOfWicketsColumns,
  fallOfWicketsColumnsData,
  scoreCardBowlerColumns,
  scoreCardBowlerData,
  scoreCardData,
  scoreCardTableColumns,
  team_a_players,
} from "./data";
import Tabs from "../../Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchScoreCardData,
  selectMatchDetailLoader,
  selectScoreCardData,
} from "../../../../redux/matchDetailSlice";

const ScoreCardPage = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [ScoreCardTabs, setScoreCardTabs] = useState([]);

  const tabClickHandler = (tab) => {
    setActiveTab(tab);
  };
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchScoreCardData(id));
  }, []);
  const scoreCard = useSelector(selectScoreCardData);
  const isLoading = useSelector(selectMatchDetailLoader);

  const selectedInning = scoreCard?.innings?.[activeTab?.key];

  const fows = useMemo(() => {
    const formatData = selectedInning?.fows?.map((each, i) => {
      return {
        name: each?.name,
        score: each?.score_at_dismissal,
        over: each?.overs_at_dismissal,
      };
    });
    return formatData;
  }, [selectedInning]);

  useEffect(() => {
    if (scoreCard?.innings?.length) {
      let tabs = scoreCard?.innings?.map((each, i) => {
        return {
          label: `${each?.short_name}`,
          key: i,
          isActive: true,
          sub_label: each?.scores_full,
        };
      });
      setScoreCardTabs(tabs);
      setActiveTab(tabs[0]);
    }
  }, [scoreCard?.innings]);
  console.log(scoreCard);
  return (
    <div>
      {typeof scoreCard === "string" ? (
        <p className="no_data_msg p-10">{scoreCard}</p>
      ) : (
        <>
          <div className="button_tabs">
            <Tabs
              tabsData={ScoreCardTabs}
              activeTab={activeTab}
              tabClickHandler={tabClickHandler}
            />
          </div>

          <Table
            data={selectedInning?.batsmen}
            activeIndex={activeTab?.key}
            // data={scoreCardData}
            columns={scoreCardTableColumns}
          />

          <div className="section">
            <p className="section_heading_1">Did not Bat</p>
            <div className="players_grid ">
              {selectedInning?.did_not_bat?.map((each) => (
                <div className="player no-border">
                  {/* <img src={each?.image} alt="" /> */}
                  <div className="right">
                    <p className="player_name">{each?.name}</p>
                    <p className="specification">{each?.specification}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bolwing_score_card">
            <p className="section_heading_1 bowling_section_head">Bowling</p>
            <Table
              data={selectedInning?.bowlers}
              columns={scoreCardBowlerColumns}
            />
          </div>
          <div className="bolwing_score_card mt-30">
            <p className="section_heading_1 bowling_section_head">
              Fall of Wickets
            </p>
            <Table data={fows} columns={fallOfWicketsColumns} />
          </div>
          {/* <div className="bolwing_score_card mt-30">
        <p className="section_heading_1 bowling_section_head">PARTNERSHIPS</p>
        <p className="section_heading_1 pl-10">No data found</p>
      </div> */}
        </>
      )}
    </div>
  );
};

export default ScoreCardPage;
