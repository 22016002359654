import React, { useEffect, useLayoutEffect } from "react";
import CustomImage from "../../Components/CustomImage/CustomImage";
import { FILE_BASE_URL } from "../../config/config";
import Video from "../../Components/Video/Video";
import SlideWrapper from "../../Components/SlideWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  clearScrollContentFetchError,
  fetchScrollContent,
} from "../../redux/scrollContentSlice";
import UseApiError from "../../Hooks/Common/UseApiError";
import Loader from "../../Components/Loader/Loader";
import { clearDetailBlog } from "../../redux/blogsSlice";

export default function HomePage() {
  const {
    data: slideData,
    isLoading,
    error,
  } = useSelector((store) => store.scroll_content || {});
  UseApiError(error, clearScrollContentFetchError);

  const dispatch = useDispatch();

  useEffect(() => {
    if(!slideData&&!slideData?.length)
    dispatch(fetchScrollContent());
  }, []);

  const { detail } = useSelector((store) => store.blogs);
  useLayoutEffect(() => {
    if (detail) {
      const id = detail?.post?.id;
      const prevItem = document.getElementById(`scroll-${id}`)
      prevItem?.scrollIntoView()
      dispatch(clearDetailBlog());
    }
  }, [detail]);

  return (
    <div id="videos" className="app__videos">
      <Loader loading={isLoading} />
      {slideData?.map((each) => (
        <SlideWrapper
          ago={each.location}
          subContent={each.description}
          title={each.title}
          description={each.description}
          likes={each.likes}
          key={each.id}
          user_image={each.user?.profile_image}
          updated_at={each.updated_at || each.created_at}
          user_name={each?.user?.name}
          id={each.id}
          type={each.type}
          html={each.html || ""}
          src={each.filename}
          like_status={each.like_status}
        >
          {each.type !== "video" ? (
            <CustomImage
              className="banner_img"
              loading="lazy"
              src={each.filename}
            />
          ) : (
            <Video url={`${FILE_BASE_URL}${each.filename}`} />
          )}
        </SlideWrapper>
      ))}
    </div>
  );
}
