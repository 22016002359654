import React from "react";
import "./GoBAckHeader.scss";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const GoBackHeader = ({ title, goBackHandler }) => {
  const navigate = useNavigate();
  const gobackClickHandler = () => {
    if (goBackHandler) goBackHandler();
    else navigate(-1);
  };
  return (
    <div className="go_back_header">
      <div onClick={gobackClickHandler} className="title_cont">
        <div className="icon">
          <BiLeftArrowAlt />
        </div>
        <p className="title">{title}</p>
      </div>
    </div>
  );
};

export default GoBackHeader;
