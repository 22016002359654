import React, { useRef, useState, useEffect } from "react";
import "./Video.css";

function Video({ url }) {
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const onVideoPress = () => {
    if (playing) {
      videoRef.current.pause();
      setPlaying(false);
    } else {
      videoRef.current.play();
      setPlaying(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    });
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      videoRef.current
        .play()
        .then(() => {
          setPlaying(true);
        })
        .catch(() => {});
    } else {
      videoRef.current.pause();
      setPlaying(false);
    }
  }, [isVisible]);

  return (
    <div className="video">
      <video
        preload="metadata"
        className="video__player"
        loop
        onClick={onVideoPress}
        ref={videoRef}
        src={url}
      ></video>
      {!playing && (
        <div onClick={onVideoPress} className="play_icon_cont">
          <img src={require("../../assets/play.png")} alt="" />
        </div>
      )}
    </div>
  );
}

export default Video;
