import dateFormat, { masks } from "dateformat";
// import noImage from '../assests/Images/noImage.png'
import moment from "moment";

// export const noImageData = noImage;

export const reloadToLoginofUnauthenticated = () => {
    let baseURL = window.location.origin;
    let pathname = window.location.pathname;
    let search = window.location.search
    let callback_url = pathname + search
    window.location.href = `${baseURL}/signin${pathname != '/' ? `/?callback=${callback_url}` : ""}  `
}
export const redirectToCallbackUrl = () => {
    const query = new URLSearchParams(window.location.search);
    const callback = query.get("callback");
    let baseURL = window.location.origin;
    if (callback) {
        let baseURL = window.location.origin;
        let callback_url = `${baseURL}${callback}`
        window.location.href = callback_url
    }
}

export const upcomingDateFormatter = (inputDate) => {
    if(!inputDate ) return null;
    const currentDate = moment();
    const inputMoment = moment(inputDate);

    if (inputMoment.isSame(currentDate, 'day')) {
        return 'Today';
    } else if (inputMoment.isSame(currentDate.clone().add(1, 'day'), 'day')) {
        return 'Tomorrow';
    } else {
        return inputMoment.format('dddd, DD MMMM');
    }
}

export const formatDateFunction = (date) => {
    let format = dateFormat(date, "d mmm, h.M tt");
    return format;
};
export const formatDateFunction2 = (date) => {
    let format = dateFormat(date, "dddd, d mmm ");
    return format;
};
export const formatDateFunction3 = (date) => {
    let format = dateFormat(date, "d mmm yyyy ");
    return format;
};
export const formatDateFunction4 = (date) => {
    let format = dateFormat(date, "mmmm yyyy ");
    return format;
};
export const formatDateFunction5 = (date) => {
    let format = dateFormat(date, "mmm yyyy ");
    return format;
};
export const formatHourAndMinutes = (date) => {
    let format = dateFormat(date, "h:M TT");
    return format;
};

function compareNumbers(a, b) {
    return a - b;
}
export const maxValueFromObject = (data, property = "earning") => {
    if (!data?.length) return
    let properyArray = data.map(each => parseInt(each[property]))
    properyArray.sort(compareNumbers)
    let maxValue = properyArray[properyArray.length - 1]
    data = data?.map(each => {
        if (each[property] == maxValue) {
            each.isTopEarner = true
        }
        return each;
    })
    return data
}

export const removeInvaildCharFromMobileField = (value) => {
    return value ? value.replace(/[^0-9]/gi, "") : "";
}



export const monthsAgoCalculator = (date, showAgo = false) => {
    var timeAgo = moment(date).fromNow(showAgo)
    return timeAgo;
}


export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];


export const last6monthsOptions = () => {
    var today = new Date();
    let options = []
    for (var i = 6; i >= 0; i -= 1) {
        let d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        let month = monthNames[d.getMonth()];
        let year = d.getFullYear();
        let monthInt = d.getMonth() + 1
        let option = {
            id: monthInt,
            year,
            month: monthInt,
            label: `${month} ${year}`,
            type: "month"
        }
        options?.push(option)
    }
    options.reverse()
    return options;
}
export const getMonthFromDate = (date) => {
    let d = new Date(date)
    let month = monthNames[d.getMonth()];
    return month;
}

export const imageBasePath = "https://smtekki.com/bjlottery/storage/app/public/images"


export const stringTrim = (string, maxLength) => {
    if (!string)
        return ""
    else if (string?.length > maxLength) {
        string = string.slice(0, maxLength) + ".."
        return string
    }
    else return string

}

export const scrollToTop = () => {
    document.getElementById('page')?.scrollTo(0, 0)
}

export const getLatestMonth = () => {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const currentDate = new Date();
    const latestMonthIndex = currentDate.getMonth();
    const latestMonthName = monthNames[latestMonthIndex];
    return latestMonthName
}
