import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa6";
import PlayersListPopup from "./PlayersListPopup/PlayersListPopup";

const PlayersViewButton = ({ logo, team_name, players }) => {
  const [showPlayerList, setShowPlayerList] = useState(false);
  const clickHandler = () => {
    setShowPlayerList(true);
  };
  const closer = () => {
    setShowPlayerList(false);
  };
  return (
    <>
      {showPlayerList && <PlayersListPopup players={players} closer={closer} />}
      <div onClick={clickHandler} className="players_open_button border_bottom">
        <div className="left">
          <div className="icon_small">
            <img src={logo} alt="" />
          </div>
          <p className="team_name link">{team_name}</p>
        </div>
        <div className="right">
          <div className="icon_x_small">
            <FaChevronRight />
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayersViewButton;
