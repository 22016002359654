import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomImage from "../../Components/CustomImage/CustomImage";
import Video from "../../Components/Video/Video";
import { FILE_BASE_URL } from "../../config/config";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import SlideWrapper from "../../Components/SlideWrapper";
import GoBackButton from "../../Components/GoBackButton/GoBackButton";

const SharePage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const fetchPost = () => {
    setLoading(true);
    axios
      .get(`/post/${id}`)
      .then((res) => {
        setLoading(false);
        setData(res.data?.data || null);
      })
      .catch((e) => {
        setLoading(false);
        toast.error(
          e?.response?.data?.message || "Something went Wrong Try again ..."
        );
      });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  const postUpdateCallback = (id, likes, isLike) => {
      let updateItem = {...data||{}}
      updateItem.like_status = !updateItem.like_status;
      updateItem.likes = likes;
      setData(updateItem);
  };
  return (
    <>
      <SlideWrapper
        ago={data?.location || ""}
        subContent={data?.description || ""}
        description={data?.title || ""}
        likes={data?.likes || ""}
        key={data?.id || ""}
        user_image={data?.user?.profile_image || ""}
        updated_at={data?.updated_at || ""}
        user_name={data?.user?.name || ""}
        id={data?.id || ""}
        type={data?.type || ""}
        html={data?.html || ""}
        src={data?.filename || ""}
        like_status={data?.like_status || ""}
        postUpdateCallback={postUpdateCallback}
      >
        <GoBackButton isHome />
        {loading && <Loader />}
        {data?.type !== "video" ? (
          <CustomImage
            className="banner_img"
            loading="lazy"
            src={data?.filename}
          />
        ) : (
          <Video url={`${FILE_BASE_URL}${data?.filename}`} />
        )}
      </SlideWrapper>
    </>
  );
};

export default SharePage;
