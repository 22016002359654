import React from "react";
import "./FantacyPointCard.scss";

const FantacyPointCard = ({
  country,
  player_name,
  player_image,
  points,
  className,
}) => {
  return (
    <div className={`fantacy_point_card ${className}`}>
      <div className="top">
        <div className="player_image">
          <img src={player_image} alt="" />
        </div>
        <div className="country_player_name">
          <p className="country">{country}</p>
          <p className="player_name">{player_name}</p>
        </div>
      </div>
      <div className="bottom">
        <p className="points">
          {points} <span className="pts_label">Pts</span>
        </p>
      </div>
    </div>
  );
};

export default FantacyPointCard;
