import { Navigate, Route } from "react-router-dom";
import UserPage from "../pages/UserPage/UserPage";
import ProfilePage from "../pages/ProfilePage/ProfilePage";

export const UserRoute = () => {
  return [
    <Route path="/profile" element={<UserPage />} />,
    <Route path="/profile-update" element={<ProfilePage />} />,
    <Route path="*" element={<Navigate to="/" replace />} />,
  ];
};

