import React from "react";
import Modal from "../../../../../Components/Modal/Modal";
import "./PlayerListPopup.scss";
import { IoMdClose } from "react-icons/io";

const PlayersListPopup = ({ players, closer }) => {
  const roleLabelGen = (player) => {
    if (player?.playing_role == "bat") return "Bat";
    else if (player?.playing_role == "bowl") return "pacer";
    else if (player?.playing_role == "all") return "All Rounder";
    return player.playing_role;
  };
  return (
    <div className="players_list_popup">
      <Modal show closeModal={closer} className="player_list_modal">
        <div className="player_list_header">
          <p onClick={closer} className="close_btn">
            <IoMdClose />
          </p>
        </div>
        <div className="players_grid">
          {players?.map((each) => (
            <div className="player">
            { each.logo_url && <img src={each?.logo_url || null} alt="" />}
              <div className="right">
                <p className="player_name">{`${each?.short_name} ${
                  each?.fielding_position == "Wicketkeeper" ? "(wk)" : ""
                }`}</p>
                <p className="specification">{roleLabelGen(each)}</p>
              </div>
            </div>
          ))}
        </div>
        {/* <p className="onBench">On Bench</p>
        <div className="players_grid">
          {players?.map((each) => (
            <div className="player">
              <img src={each?.image} alt="" />
              <div className="right">
                <p className="player_name">{each?.player_name}</p>
                <p className="specification">{each?.specification}</p>
              </div>
            </div>
          ))}
        </div> */}
      </Modal>
    </div>
  );
};

export default PlayersListPopup;
