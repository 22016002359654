import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AllRoutes from './Routes/Routes'
import setupAxios from './redux/setupAxios';
import axios from 'axios';
import PWAInstaller from './Components/Common/PwaIntaller';

setupAxios(axios, store)

function App() {
  return (
    <div id='app' className="App" >
      <PWAInstaller />
      <ToastContainer />
      <Provider store={store}>
        <BrowserRouter>
          <AllRoutes />
          <Navbar />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
