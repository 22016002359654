import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import GoBackHeader from "../../components/GoBackHeader/GoBackHeader";
// import { fetchNotifications } from "../../redux/NotificationSlice";
import { monthsAgoCalculator } from "../../utils/utils";
import NoNotifications from "./NoNotifications";
import "./Notifcations.css";
import Notification from "./Notification";
import Loader from "../../Components/Loader/Loader";
const Notifications = () => {
  const { data, isLoading } = useSelector(
    (store) => store?.notifications || {}
  );


  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const clearAllNotifications = () => {
    setLoading(true);
    axios
      .get(`/notification/destroy`)
      .then((res) => {
        setLoading(false);
        if (res?.data?.response) {
          // dispatch(fetchNotifications());
          toast.success(
            res?.data?.message || "Notifications Cleared Successfully..."
          );
        } else {
          toast.error(
            res?.data?.message || "Something went wrong try again..."
          );
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(
          e?.response?.data?.message || "Something went wrong try again..."
        );
      });
  };
  useEffect(() => {
    // dispatch(fetchNotifications());
    return () => {
      // dispatch(fetchNotifications());
    };
  }, []);

  return (
    <div className="page notification-page">
      <Loader loading={isLoading || loading} />
      <div className="bg_primary">
        {/* <GoBackHeader title="" /> */}
        <div className="notifications">
          {data?.map((each, i) => (
            <Notification
              key={i}
              title={each.description || ""}
              created_at={monthsAgoCalculator(each.sent_at || "")}
              is_latest={each.status==0}
              type={each.type}
            />
          ))}
        </div>
        {data?.length ? (
          <div onClick={clearAllNotifications} className="clear-all-cont">
            <p>Clear all</p>
          </div>
        ) : null}
        {!data?.length && !isLoading && <NoNotifications />}
      </div>
    </div>
  );
};

export default Notifications;
