import React from "react";
// import { my_icons } from "../../assests/jsxSvgs/jsxSvgsExport";
import parse from "html-react-parser";

const Notification = ({ title, created_at, is_latest, type }) => {
  return (
    <div className={`notification ${is_latest ? "active" : ""}`}>
      <div className="icon_cont">
        {/* {type === "Downline" && my_icons.user}
        {type === "Redeem" && my_icons.gift1}
        {type === "Click" && my_icons.link} */}
      </div>
      <div className="flex flex-column">
        <p>{parse(title)}</p>
        <p className="time">{created_at}</p>
      </div>
    </div>
  );
};

export default Notification;
