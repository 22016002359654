import React, { useState } from "react";
import Modal from "./Modal/Modal";
import ModalHeader from "./Modal/ModalHeader";
import ModalBody from "./Modal/ModalBody";
import ModalFooter from "./Modal/ModalFooter";
import parse from "html-react-parser";
import CustomImage from "./CustomImage/CustomImage";

const ModalContent = ({ show, setShow, html, title, description, imgsrc }) => {
  const closer = () => {
    setShow(false);
  };

  return (
    <Modal show={show} closeModal={closer}>
      <ModalHeader title={title} closeModal={closer} />
      <ModalBody className="some class names for custom styles">
        <div className="news_image">
          <CustomImage src={imgsrc} />
        </div>
        <p className="description_detail">{description}</p>
        {parse(html)}
      </ModalBody>
      {/* <ModalFooter className="some class names for custom styles">
          modal footer
        </ModalFooter> */}
    </Modal>
  );
};

export default ModalContent;
