import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader";
import { useCookies } from "react-cookie";

const PWAInstaller = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [show, setShow] = useState(true);
  const [showPwaInstallCookies, setShowPwaInstallCookies] = useState(false);
  const [cookies, setCookie] = useCookies(["showPwa"]);
  const [pwaAlreadyPresent, setPwaAlreadyPresent] = useState(false);

  const setCookiesFunction = () => {
    setCookie("hidePwa", "true", {
      expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
    });
  };

  useEffect(() => {
    const hasCookie = cookies.hidePwa === "true";
    if (hasCookie) {
      setShowPwaInstallCookies(false);
    } else {
      setTimeout(()=>{
        setShowPwaInstallCookies(true);
      },10000)
   
    }
  }, [cookies]);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      console.log(e, "testing e value")
      setDeferredPrompt(e);
    };
    console.log(window,'soem windo')
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    // return () => {
    //   window.removeEventListener(
    //     "beforeinstallprompt",
    //     handleBeforeInstallPrompt
    //   );
    // };
  }, []);

  useEffect(() => {
    const fun = async () => {
      if (typeof navigator?.getInstalledRelatedApps == "function") {
        const relatedApps = await navigator?.getInstalledRelatedApps();
        const PWAisInstalled = relatedApps?.length > 0;
        if (PWAisInstalled) {
          setPwaAlreadyPresent(true);
        }
      }
    };
    fun();
  }, []);

  const handleInstallButtonClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      setDeferredPrompt(null);
      if (outcome === "accepted") {
        setCookiesFunction();
        setShow(false);
      } else if (outcome === "dismissed") {
        setShow(false);
        setCookiesFunction();
      }
    }
  };

  const closePwaPopupHandler = () => {
    setShow(false);
    setCookiesFunction();
  };

  const [showOpenPwa, setOpenPwa] = useState(true);

  if (pwaAlreadyPresent) {
    <Modal className="pwa_popup" show={showOpenPwa}>
      <ModalHeader closeModal={() => setOpenPwa(false)} />
      <h2>BajiShorts PWA</h2>
      <p className="title">
        You already Installed BajiShorts PWA App. Find on Homescreen and open
        app to get better User experience.
      </p>
      <div className="mt-5">
        <p className="light">
          You don't need to download PWAs from app stores, reducing the risk of
          accidentally installing fake apps or harmful software
        </p>
      </div>
      <button
        className="btn btn-primary"
        id="installButton"
        onClick={handleInstallButtonClick}
      >
        Ok
      </button>
    </Modal>;
  }

  return (
    showPwaInstallCookies && (
      <Modal className="pwa_popup" show={show}>
        <ModalHeader closeModal={closePwaPopupHandler} />
        <h2>Install Cric24X</h2>
        <p className="title">
          PWAs use modern web technologies and capabilities to provide an
          app-like experience directly through a web browser.
        </p>
        <div className="mt-5">
          <p className="light">
            You don't need to download PWAs from app stores, reducing the risk
            of accidentally installing fake apps or harmful software
          </p>
          {/* <p className="light">
            No Worries About Viruses: Enjoy peace of mind with PWAs, as they are
            built to resist harmful viruses and keep your device safe and sound
          </p>
          <p className="light">
            Your Information is Secure: PWA apps prioritize your safety by using
            special technology that protects your personal data from hackers and
            eavesdroppers.
          </p> */}
        </div>
        <button
          className="btn btn-primary"
          id="installButton"
          onClick={handleInstallButtonClick}
        >
          Add to Home Screen
        </button>
      </Modal>
    )
  );
};

export default PWAInstaller;
