import React from "react";
import UpcomingGroup from "./Components/UpcomingGroup/UpcomingGroup";
import { upcoming_matches } from "./data";
import {
  fetchUpcomingMatches,
  selectMatchesLoader,
  selectUpcomingData,
} from "../../redux/MatchesSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import { useEffect } from "react";
import { upcomingDateFormatter } from "../../utils/utils";

const UpcomingPage = () => {
  const upcomingData = useSelector(selectUpcomingData);
  const isLoading = useSelector(selectMatchesLoader);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUpcomingMatches());
  }, []);
  return (
    <>
      {isLoading && <Loader />}

      {upcomingData &&
        Object.keys(upcomingData)?.map((key) => (
          <UpcomingGroup
            key={key}
            title={upcomingDateFormatter(key)}
            matches={upcomingData[key]}
          />
        ))}
      {/* {upcomingData?.map((each) => (
        <UpcomingGroup
          key={each?.id}
          title={each?.group_name}
          matches={each?.matches}
        />
      ))} */}
    </>
  );
};

export default UpcomingPage;
