import React from "react";

const Row = ({ title, left_data, right_data, isGreen }) => {
  return (
    <div className="row">
      <p className={`text ${isGreen&&left_data>right_data&&" green"}`}>{left_data || 0}</p>
      <p className="small_title">{title}</p>
      <p className={`text ${isGreen&&right_data>left_data&&" green"}`}>{right_data || 0}</p>
    </div>
  );
};

export default Row;
