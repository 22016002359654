import React from "react";
import "./TeamComparision.scss";
import Row from "./Row";

const TeamComparision = ({
  data,
  teama_logo,
  teamb_logo,
  teama_name,
  teamb_name,
}) => {
  return (
    <div className="team_comparision">
      <div className="heading row">
        <div className="team_info">
          <div className="logo">
            <img src={teama_logo} alt="" />
          </div>
          <div className="content">
            <p className="team_name">{teama_name}</p>
            <p className="mini_title">Vs all teams</p>
          </div>
        </div>
        <div className="team_info">
          <div className="logo">
            <img src={teamb_logo} alt="" />
          </div>
          <div className="content">
            <p className="team_name">{teamb_name}</p>
            <p className="mini_title">Vs all teams</p>
          </div>
        </div>
      </div>
      <Row
        title="Matches Played"
        left_data={data?.teama?.details?.length}
        right_data={data?.teamb?.details?.length}
      />
      <Row
        title="Win"
        left_data={data?.teama?.Win}
        right_data={data?.teamb?.Win}
      />
      <Row
        isGreen
        title="Average Score"
        left_data={
          ((data?.teama?.highest_score || 0) +
            (data?.teama?.lowest_score || 0)) /
          2
        }
        right_data={
          ((data?.teamb?.highest_score || 0) +
            (data?.teamb?.lowest_score || 0)) /
          2
        }
      />
      <Row
        isGreen
        title="Highest Score"
        left_data={data?.teama?.highest_score}
        right_data={data?.teamb?.highest_score}
      />
      <Row
        isGreen
        title="Lowest Score"
        left_data={data?.teama?.lowest_score}
        right_data={data?.teamb?.lowest_score}
      />
    </div>
  );
};

export default TeamComparision;
