
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'

const initialState = {
    data: null,
    isLoading: false,
    detail: null,
    error: null
}

export const fetchAllBlogs = createAsyncThunk('fetchPredictions', (queryString, { rejectWithValue }) => {
    return axios.get(`/post-prediction${queryString || ""}`).then(res => res.data)
});
export const fetchBlog = createAsyncThunk('fetchBlog', (id, { rejectWithValue }) => {
    return axios.get(`/post/${id}`).then(res => res.data)
});

export const blogsSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        clearDetailBlog: (state) => {
            state.detail = null;
        },
        clearBlogError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllBlogs.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchAllBlogs.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload?.data;
        })
        builder.addCase(fetchAllBlogs.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })

        //////////// FETCH SINGLE BLOG ////////////////
        builder.addCase(fetchBlog.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchBlog.fulfilled, (state, action) => {
            state.isLoading = false
            state.detail = action.payload?.data;
        })
        builder.addCase(fetchBlog.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })
    }
})

export default blogsSlice.reducer;

export const { clearBlogError, clearDetailBlog } = blogsSlice.actions;



