import React from "react";
import "./UserPage.css";
import ProfileSettingButton from "./ProfileSettingButton";
// import { my_icons } from "../../assests/jsxSvgs/jsxSvgsExport";
import { useDispatch, useSelector } from "react-redux";
import GoBackButton from "../../Components/GoBackButton/GoBackButton";
import { FaUserAlt } from "react-icons/fa";
import { clearUserInfo } from "../../redux/userSlice";
import { toast } from "react-toastify";

const UserPage = () => {
  const { user } = useSelector((store) => store?.user || {});

  const dispatch = useDispatch()
  const logoutHandler = () => {
    dispatch(clearUserInfo())
    toast.success("Logged out Successfully...")
  }

  return (
    <>
      <GoBackButton />
      <div className="page user_page">
        <div className="top_section">
        </div>
        <div className="circle"></div>
        <div className="profile_image_cont">
          {!user?.profile_image ? (
            <FaUserAlt />
          ) : (
            <img src={user?.profile_image} alt="" />
          )}
        </div>
        <div className="bottom_section">
          <div className="user_details">
            <p className="username">{user?.name || ""} </p>
            <p className="email">{user?.email || ""}</p>
          </div>
          <div className="action_buttons p-20">
            <ProfileSettingButton title="My account Settings" route="/profile-update" />
            {/* <ProfileSettingButton
              title="Notifications"
              route="/notifications"
            /> */}
          </div>
        </div>
        <div className="footer_section">
          <button onClick={logoutHandler} className="btn btn-primary">Logout</button>
        </div>
      </div>
    </>
  );
};

export default UserPage;
