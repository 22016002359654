import React from "react";
import Header from "../BlogsPage/Header";
import CategoriesCont from "../BlogsPage/CategoriesCont";
import { RECOMMEND_TYPES, blogsData } from "../BlogsPage/data";
import BlogCard from "../BlogsPage/BlogCard/BlogCard";
import BlogRecommendButton from "../BlogsPage/BlogRecommendButton";
import "./AllBlogsPage.css";
import StickyWrapper from "../../Components/Common/StickyWrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearBlogError, fetchAllBlogs } from "../../redux/blogsSlice";
import Loader from "../../Components/Loader/Loader";
import UseApiError from "../../Hooks/Common/UseApiError";
import { fetchCategories } from "../../redux/categoriesSlice";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { scrollToTop } from "../../utils/utils";

const AllBlogsPage = () => {
  const { isLoading, data, error } = useSelector((store) => store.blogs);
  UseApiError(error, clearBlogError);
  const dispatch = useDispatch();

  const { search, pathname } = useLocation();
  const currentCategory = useMemo(() => {
    if (!search) return 12;
    let queryParams = new URLSearchParams(search);
    let categoryId = queryParams.get("category");
    return categoryId;
  }, [search]);

  useEffect(() => {
    if (!currentCategory == 12 || currentCategory == 11)
      dispatch(fetchAllBlogs());
    else dispatch(fetchAllBlogs(`?category=${currentCategory}`));
  }, [currentCategory]);

  

  return (
    <div id="page" className="blogs_page all_blogs">
      <Loader loading={isLoading} />
      <StickyWrapper>
        <Header />
        <CategoriesCont />
      </StickyWrapper>
      <div className="p-10">
        {data?.post?.data?.map((each) => (
          <BlogCard
            banner_image={each.filename}
            postedByImage={each.user?.profile_image}
            posted_by_name={each.user?.name ||"User"}
            posted_at={each.posted_at || each.created_at}
            data={each}
            title={each?.title || " "}
          />
        ))}

        {
          !data?.post?.data?.length && !isLoading && <p className="no_data_msg">No data found...</p>
        }

        <div className="cols-2 mt-20">
          {data?.trending_categories?.map((each) => (
            <BlogRecommendButton
              id={each.id}
              title={each.name}
              type={RECOMMEND_TYPES?.CATEGORY}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllBlogsPage;
