import React from "react";
import { BounceLoader, ClipLoader, DotLoader } from "react-spinners";
import "./Loader.css";

const Loader = ({ loading, size = 50 }) => {
  return (
    <div className="loader-root">
      <DotLoader color={"#01469a"} loading={loading} size={size} />
    </div>
  );
};

export default Loader;

