import React from "react";
import "./BlogCard.css";
import CustomImage from "../../../Components/CustomImage/CustomImage";
import { HiDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { monthsAgoCalculator } from "../../../utils/utils";

const BlogCard = ({
  banner_image,
  posted_by_name,
  title,
  posted_at,
  postedByImage,
  data,
  isBlogDetail
}) => {
  const menuClickHandler = () => {
    alert("menu clicked");
  };

  const navigate = useNavigate();
  const blogclickHanlder = () => {
    if(isBlogDetail) return;
    navigate(`/blogs/${data.id}`);
  };
  return (
    <div className="blog_card_root">
      <div onClick={blogclickHanlder} className="blog_img_cont">
        <CustomImage src={banner_image} className="blog_img" />
        {/* <img src={banner_image} className="blog_img" /> */}
        <p className="banner_title">{title}</p>
      </div>
      <div className="bottom_cont">
        <div className="left_cont">
          <div className="posted_img_cont">
            {/* <CustomImage src={postedByImage} className="posted_img" /> */}
            <img src={postedByImage} className="posted_img" />
          </div>
          <div className="title_cont">
            <p className="title">{posted_by_name || ""}</p>
            <p className="posted_at">{monthsAgoCalculator(posted_at)}</p>
          </div>
        </div>
        <div onClick={menuClickHandler} className="menu_cont ">
          <HiDotsVertical />
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
