import React, { useState } from "react";
import Tabs from "./Tabs/Tabs";
import { FINISHED, FOR_YOU, LIVE, UPCOMING, homeTabs } from "./data";
import "./Matches.scss";
import Header from "./Components/Header/Header";
import ForYouPage from "./ForYouPage";
import FinishedPage from "./FinishedPage";
import LivePage from "./LivePage";
import UpcomingPage from "./UpcomingPage";

const Matches = () => {
  const [activeTab, setActiveTab] = useState(homeTabs[0]);
  const tabClickHandler = (key) => {
    setActiveTab(key);
    console.log(key);
  };
  return (
    <div className="matches_page">
      <Header />
      <div className="sticky">
        <Tabs
          activeTab={activeTab}
          tabClickHandler={tabClickHandler}
          tabsData={homeTabs}
        />
      </div>
      <div className="matches_cont">
        {activeTab.key == LIVE && <LivePage />}
        {activeTab.key == FOR_YOU && <ForYouPage />}
        {activeTab.key == UPCOMING && <UpcomingPage />}
        {activeTab.key == FINISHED && <FinishedPage />}
      </div>
    </div>
  );
};

export default Matches;
