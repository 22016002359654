
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const fetchAllCasinos = createAsyncThunk('casino', (queryString, { rejectWithValue }) => {
    return axios.get(`/casino`).then(res => res.data)
});

export const casinosSlice = createSlice({
    name: 'casino',
    initialState,
    reducers: {
        clearCasinoError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllCasinos.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchAllCasinos.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload?.data;
        })
        builder.addCase(fetchAllCasinos.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })

    }
})

export default casinosSlice.reducer;
export const { clearCasinoError } = casinosSlice.actions;



