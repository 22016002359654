import React, { useMemo } from "react";
import Breadcrumb from "../../../Components/Breadcrumb/Breadcrumb";
import "./TeamForm.scss";
import MatchCard from "./MatchCard/MatchCard";
const TeamForm = ({ data, team_data }) => {
  const lastFiveWinnings = useMemo(() => {
    let jsx = data?.winloose.map((status) => (
      <div className={`win_label ${matchWinLabelGenerate(status)}`}>
        {matchWinLabelGenerate(status)}
      </div>
    ));
    return jsx;
  }, [data]);
  return (
    <div className="team_form">
      <Breadcrumb
        series_logo={team_data?.logo_url}
        title={team_data?.short_name}
        rightJsx={<div className="win_labels_cont">{lastFiveWinnings}</div>}
      >
        {data?.Details.map((match, i) => (
          <MatchCard match={match} WinStatus={matchWinLabelGenerate(data?.winloose?.[i])} />
        ))}
      </Breadcrumb>
    </div>
  );
};

export default TeamForm;

export const matchWinLabelGenerate = (status) => {
  let label = "W";
  if (status == "L") label = "L";
  else if (status == "Drawn") label = "T";
  return label;
};
