import React, { useEffect, useState } from "react";
import { AiFillLike } from "react-icons/ai";
import { FaShare } from "react-icons/fa";
import CustomImage from "./CustomImage/CustomImage";
import moment from "moment";
import { toast } from "react-toastify";
import ModalContent from "./ModalContent";
import axios from "axios";
import { Link } from "react-router-dom";
import Description from "./Description";
import { useDispatch } from "react-redux";
import { updateLikedStatus } from "../redux/scrollContentSlice";

const SlideWrapper = ({
  children,
  title,
  description,
  likes,
  user_image,
  updated_at,
  user_name,
  id,
  type,
  html,
  subContent,
  like_status,
  src,
}) => {
  const shareClickHandler = () => {
    let origin = window.location.origin;
    let url = `${origin}/share/${id}`;
    try {
      navigator.clipboard.writeText(url);
      toast.success("Share Link Copied to Clipboard");
    } catch {
      alert(`Share url : ${url}`);
    }
    console.log(url);
  };

  const [showDetail, setShowDetail] = useState(false);
  const [liked, setLiked] = useState(like_status);
  const dispatch = useDispatch();

  const likeApiHandler = () => {
    setLiked(!like_status);
    axios
      .post("/likes", { post_id: id })
      .then((res) => {
        if (res.data.response) {
          dispatch(updateLikedStatus({ id, likes: res.data?.data?.likes }));
        }
      })
      .catch((e) => {
        toast.error("Something went wrong try again...");
      });
  };

  return (
    <div className="SlideWrapper" id={`scroll-${id}`}>
      {type == "image" && (
        <ModalContent
          html={html}
          show={showDetail}
          setShow={setShowDetail}
          description={subContent}
          title={description}
          imgsrc={src}
        />
      )}
      <div className="bottom_cont">
        <div>
          <div>
            <p className="title">{title}</p>
            <Description id={id} description={description || ""} />
          </div>
        </div>
        <div className="flex align-center">
          {type == "image" && (
            <Link to={`/blogs/${id}`}>
              <button className="view_post">View Post</button>
            </Link>
          )}
          <div className="user_time_cont">
            {/* <p className="username">{user_name || "User"}</p> */}
            <p className="updated_time">{moment(updated_at || "").fromNow()}</p>
          </div>
        </div>
      </div>
      <div className="bottom_right_sticky">
        <div  className="user_img">
          {user_image ? (
            <img src={user_image} alt="" className="flag" />
          ) : (
            <img src={require("../assets/user.jpg")} alt="" className="flag" />
          )}
        </div>
        <div className="like_icon">
          <div className="icon_cont" onClick={likeApiHandler}>
            <AiFillLike color={liked ? "#9cbdff" : "white"} />
          </div>
          <p>{likes}</p>
        </div>
        <div onClick={shareClickHandler} className="icon_cont">
          <FaShare />
        </div>
      </div>
      {children}
    </div>
  );
};

export default SlideWrapper;
