import { configureStore } from '@reduxjs/toolkit'
import scrollContentReducer from './scrollContentSlice'
import userReducer from './userSlice'
import blogsReducer from './blogsSlice'
import casinoReducer from './casinoSlice'
import categoryReducer from './categoriesSlice'
import matchesSlice from './MatchesSlice'
import matchDetailReducer from './matchDetailSlice'

export const store = configureStore({
    reducer: {
        scroll_content: scrollContentReducer,
        user: userReducer,
        blogs: blogsReducer,
        casino: casinoReducer,
        categories: categoryReducer,
        matches: matchesSlice,
        matchDetail: matchDetailReducer
    },
})