import React from "react";
import { RECOMMEND_TYPES } from "./data";
import { useNavigate } from "react-router-dom";
import { BiSolidChevronRight } from "react-icons/bi";
import { scrollToTop, stringTrim } from "../../utils/utils";

//type = blog || category
const BlogRecommendButton = ({ title, id, type }) => {
  const navigate = useNavigate();
  const blogClickHandler = () => {
    if (type === RECOMMEND_TYPES.BLOG) {
      navigate(`/blogs/${id}`);
      setTimeout(() => {
        scrollToTop();
      }, 100);
    } else if (type === RECOMMEND_TYPES.CATEGORY) {
      navigate(`/blogs?category=${id}`);
      setTimeout(() => {
        scrollToTop();
      }, 100);
    }
  };
  window.scrollTo(0, 0);

  return (
    <div className="recommended_button" onClick={blogClickHandler}>
      <p className="title"> {stringTrim(title, 40)}</p>
      <div className="icon_cont">
        <BiSolidChevronRight />
      </div>
    </div>
  );
};

export default BlogRecommendButton;
