export const pointsTableData = [
    {
        team_name: "IND",
        team_logo: "https://picsum.photos/200/200",
        points: '10',
        wickets: 4,
        l: 1,
        nrr: "+123",
        pts: "18"
    },
    {
        team_name: "AFG",
        team_logo: "https://picsum.photos/199/199",
        points: '10',
        wickets: 4,
        l: 1,
        nrr: "+123",
        pts: "18"
    },
    {
        team_name: "AUS",
        team_logo: "https://picsum.photos/198/198",
        points: '10',
        wickets: 4,
        l: 1,
        nrr: "+123",
        pts: "18"
    },
    {
        team_name: "PAK",
        team_logo: "https://picsum.photos/197/197",
        points: '10',
        wickets: 4,
        l: 1,
        nrr: "+123",
        pts: "18"
    },
    {
        team_name: "TUR",
        team_logo: "https://picsum.photos/197/197",
        points: '10',
        wickets: 4,
        l: 1,
        nrr: "+123",
        pts: "18"
    },
    {
        team_name: "IND",
        team_logo: "https://picsum.photos/197/197",
        points: '10',
        wickets: 4,
        l: 1,
        nrr: "+123",
        pts: "18"
    },
]
export const pointsTablecolumns = [
    {
        header: "Team",
        accessor: "title",
        data_left_icon: "thumb_url",
        type: "th_small bold flex-1 team_data"
    },
    {
        header: "P",
        accessor: "Pts",
        type: "th_small td_small"
    },
    {
        header: "W",
        accessor: "W",
        type: "th_small td_small"
    },
    {
        header: "L",
        accessor: "L",
        type: "th_small td_small"
    },
    {
        header: "NRR",
        accessor: "NRR",
        type: "th_small td_small"
    },
    {
        header: "pts",
        accessor: "Pts",
        type: "th_small red_bold "
    },
]