import React, { useState } from "react";
import "./FantacyPage.scss";
import {
  FANTACY_POINTS,
  MOST_RUNS,
  MOST_SIXES,
  MOST_WICKETS,
  allFantacyData,
  fantacyPointsData,
  playerStatsTabs,
} from "./data";
import FantacyPointCard from "./FantacyPointCard/FantacyPointCard";
import ShowAllPopup from "./ShowAllPoup/ShowAllPopup";
import Tabs from "../../Tabs/Tabs";

const FantacyPage = () => {
  const [showAllModal, setShowAllModal] = useState(false);
  const [activeTab, setActiveTab] = useState(playerStatsTabs[0]);
  const showAllClickHandler = () => {
    setShowAllModal(true);
  };
  const closer = () => {
    setShowAllModal(false);
  };
  const tabClickHandler = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      {showAllModal && (
        <ShowAllPopup fantacyPoints={allFantacyData} closer={closer} />
      )}
      <div className="fantacy_page">
        <div className="fantacy_header">
          <p className="section_heading">Top Fantacy Points</p>
          <p onClick={showAllClickHandler} className="section_heading link">
            See All
          </p>
        </div>
        <div className="p-10 ">
          <div className="fantacy_cards_cont overflow-x-auto">
            {fantacyPointsData?.map((each, i) => (
              <FantacyPointCard
                {...each}
                className={i % 2 == 0 ? "yellow" : "blue"}
              />
            ))}
          </div>
        </div>
        <div className="fantacy_header mt-10">
          <p className="section_heading">Player Stats in Series</p>
          <p className="section_heading link">Analysis</p>
        </div>

        <div>
          <div className="button_tabs overflow-x-auto">
            <Tabs
              tabsData={playerStatsTabs}
              activeTab={activeTab}
              tabClickHandler={tabClickHandler}
            />
          </div>
        </div>
        <div className="player_stats_table_cont">
          {activeTab.key == FANTACY_POINTS &&
            "Fantacy points player stats table show here"}
          {activeTab.key == MOST_RUNS && "Most Runs stats table show here"}
          {activeTab.key == MOST_WICKETS &&
            "Most Wickets stats table show here"}
          {activeTab.key == MOST_SIXES && "Most Sixes stats table show here"}
        </div>
      </div>
    </>
  );
};

export default FantacyPage;
