import React from "react";
import "./MatchCard.scss";
import { matchWinLabelGenerate } from "../TeamForm";

const MatchCard = ({ match, WinStatus }) => {
  return (
    <div className="match_card">
      <div className="left">
        <div className="match_details mb_15">
          <img src={match.teama?.logo_url} alt="" className="logo" />
          <p className="short_name">{match.teama.short_name}</p>
          <div className="score_wickets">
            <p className="score">{match.teama.score}</p>-
            <p className="wickets">{match.teama.wickets}</p>
          </div>
          <p className="overs">{match.teama.scores}</p>
        </div>
        <div className="match_details">
          <img src={match.teamb?.logo_url} alt="" className="logo" />
          <p className="short_name">{match.teamb?.short_name}</p>
          <div className="score_wickets">
            <p className="score">{match.teamb?.score}</p> -
            <p className="wickets">{match.teamb?.wickets}</p>
          </div>
          <p className="overs">{match.teamb?.scores}</p>
        </div>
      </div>
      <div className="right">
        <div className={`win_label ${WinStatus}`}>
          {WinStatus}
        </div>
        <div>
          <p className="match_count">{match.subtitle||0}</p>
          <p className="series_name">{match.competition?.abbr}</p>
        </div>
      </div>
    </div>
  );
};

export default MatchCard;


