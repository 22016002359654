
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const fetchScrollContent = createAsyncThunk('fetchScrollContent', (params, { rejectWithValue }) => {
    return axios.get('/post').then(res => res.data)
});




export const scrollContentSlice = createSlice({
    name: 'scrollContent',
    initialState,
    reducers: {
        clearScrollContentFetchError: (state, action) => {
            state.error = null;
        },
        updateLikedStatus: (state, action) => {
            let { id, likes } = action.payload || {}
            let update = state.data;
            let index = update?.findIndex((each) => each.id == id);
            if (index != -1) {
                let updateItem = update[index];
                updateItem.like_status = !updateItem.like_status;
                updateItem.likes = likes;
                update[index] = updateItem;
                state.data = update;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchScrollContent.pending, (state, action) => {
            state.isLoading = true
        });
        builder.addCase(fetchScrollContent.fulfilled, (state, action) => {
            state.data = action.payload?.data?.data;
            state.isLoading = false;
        });
        builder.addCase(fetchScrollContent.rejected, (state, action) => {
            state.error = action?.error?.message || "Something Went wrong Try again..";
            state.isLoading = false;

        });
    }

})

export default scrollContentSlice.reducer;
export const { clearScrollContentFetchError, updateLikedStatus } = scrollContentSlice.actions



