import React, { useEffect, useMemo, useState } from "react";

const Description = ({ description }) => {
  const [isShort, setIsShort] = useState(true);
  const reduceStringSizeTo = (length, text, start) => {
    return text.substring(start || 0, length).trim();
  };
  const reducedDescription = useMemo(() => {
    if (description?.length < 50) return description;
    else
      return isShort
        ? reduceStringSizeTo(50, description) + "..."
        : description;
  }, [isShort, description]);

  useEffect(() => {
    let ele = document.getElementById("videos");
    let scrollTop = ele.scrollTop + 1;
    ele.scrollTop = scrollTop;
  }, [isShort]);

  return (
    <p
      onClick={() => {
        setIsShort((state) => !state);
      }}
      className="description"
    >
      {reducedDescription}
    </p>
  );
};

export default Description;
