export const LIVE = "LIVE";
export const FOR_YOU = "FOR_YOU";
export const UPCOMING = "UPCOMING";
export const FINISHED = "FINISHED";

export const homeTabs = [
    {
        label: "Live",
        key: LIVE,
        isActive: true
    },
    {
        label: "For You",
        key: FOR_YOU
    },
    {
        label: "Upcoming",
        key: UPCOMING
    },
    {
        label: "Finished",
        key: FINISHED
    },
]

export const matches_data = [
    {
        id: 1,
        label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
        is_live: true,
        match_1_info: {
            logo: require('./ne_16.png'),
            short_name: "NED",
            is_opt_bat: true,
            is_batting: true,
            score: 250,
            wickets: 9,
            overs: 49.0
        },
        match_2_info: {
            logo: require("./sr_16.png"),
            short_name: "SL",
        }

    },
    {
        id: 2,
        label: "18th ODI, lorem espus text",
        is_live: true,
        match_1_info: {
            logo: require('./ne_16.png'),
            short_name: "NED",
            is_opt_bat: true,
            is_batting: true,
            score: 100,
            wickets: 9,
            overs: 12.0
        },
        match_2_info: {
            logo: require("./sr_16.png"),
            short_name: "SL",
        }

    },

]

export const series_data = [
    {
        id: 1,
        title: "World cup 2023",
        series_logo: "https://picsum.photos/200/200",
        matches: [
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                match_1_info: {
                    logo: require('./ne_16.png'),
                    short_name: "NED",
                    is_opt_bat: true,
                    is_batting: true,
                    score: 250,
                    wickets: 9,
                    overs: 49.0
                },
                match_2_info: {
                    logo: require("./sr_16.png"),
                    short_name: "SL",
                }
        
            },
            {
                id: 2,
                label: "18th ODI, lorem espus text",
                is_live: true,
                match_1_info: {
                    logo: require('./ne_16.png'),
                    short_name: "NED",
                    is_opt_bat: true,
                    is_batting: true,
                    score: 100,
                    wickets: 9,
                    overs: 12.0
                },
                match_2_info: {
                    logo: require("./sr_16.png"),
                    short_name: "SL",
                }
        
            },
        
        ]
    },
    {
        id: 2,
        is_open: true,
        title: "WBBL 2023",
        series_logo: "https://picsum.photos/300/300",
        matches:  [
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                match_1_info: {
                    logo: require('./ne_16.png'),
                    short_name: "NED",
                    is_opt_bat: true,
                    is_batting: true,
                    score: 250,
                    wickets: 9,
                    overs: 49.0
                },
                match_2_info: {
                    logo: require("./sr_16.png"),
                    short_name: "SL",
                }
        
            },
            {
                id: 2,
                label: "18th ODI, lorem espus text",
                is_live: true,
                match_1_info: {
                    logo: require('./ne_16.png'),
                    short_name: "NED",
                    is_opt_bat: true,
                    is_batting: true,
                    score: 100,
                    wickets: 9,
                    overs: 12.0
                },
                match_2_info: {
                    logo: require("./sr_16.png"),
                    short_name: "SL",
                }
        
            },
        
        ]
    },
    {
        id: 3,
        title: "Lorem WC Asia A",
        series_logo: "https://picsum.photos/300/300",
        matches: matches_data
    },
    {
        id: 3,
        title: "Epsum WC Asia Q Final ",
        series_logo: "https://picsum.photos/300/300",
        matches: matches_data
    },
    {
        id: 3,
        title: "Captain WC Asia Q ",
        series_logo: "https://picsum.photos/300/300",
        matches: matches_data
    },
]


export const upcoming_matches = [
    {
        id: 1,
        group_name: "Tommorrow , 31 October",
        matches: [
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/301/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/302/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/303/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/304/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
        ]

    },
    {
        id: 1,
        group_name: "Wednesday , 01 November",
        matches: [
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/305/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/306/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/307/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/308/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
        ]

    },
    {
        id: 1,
        group_name: "Wednesday , 01 November",
        matches: [
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/305/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/306/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/307/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/308/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
        ]

    },
    {
        id: 1,
        group_name: "Wednesday , 01 November",
        matches: [
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/305/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/306/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/307/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/308/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
        ]

    },
]


export const finishedMatchesData = [
    {
        id: 1,
        group_name: "Today , 30 October",
        matches: [
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/301/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/302/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/303/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/304/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
        ]

    },
    {
        id: 1,
        group_name: "Yesterday , 29 October",
        matches: [
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/301/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/302/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/303/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/304/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
        ]

    },
    {
        id: 1,
        group_name: "Yesterday , 29 October",
        matches: [
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/301/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/302/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
            {
                id: 1,
                label: "19th ODI, Bharat Ratna Shri Atal Bihari Vijpayee lorem epsum text",
                is_live: true,
                start_at: "9:30 AM",
                match_1_info: {
                    logo: "https://picsum.photos/303/300",
                    short_name: "Melborne Renegades Womens",
                },
                match_2_info: {
                    logo: "https://picsum.photos/304/300",
                    short_name: "Lorem Epsum Team player",
                }

            },
        ]

    },
]

