
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const fetchCategories = createAsyncThunk('fetchCategories', (queryString, { rejectWithValue }) => {
    return axios.get(`/category`).then(res => res.data)
});


export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        clearCategoryError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCategories.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload?.data;
        })
        builder.addCase(fetchCategories.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })

      
    }
})

export default categorySlice.reducer;

export const { clearCategoryError } = categorySlice.actions;





