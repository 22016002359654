import React from "react";
import CustomImage from "../../Components/CustomImage/CustomImage";
import { AiFillStar } from "react-icons/ai";
const CasinoCard = ({
  banner_title,
  title,
  description,
  rating,
  url,
  img,
}) => {
  const cardClickHandler = () => {
    window.location.href = url;
  };
  return (
    <div className="casino_card">
      <div className="img_cont">
        {/* <img src={img} alt="" /> */}
        <CustomImage src={img} alt="" />
        <p className="banner_title">{banner_title}</p>
      </div>
      <div className="middle_cont">
        <p className="title">{title}</p>
        <p className="description">{description}</p>
      </div>
      <div className="bottom_cont">
        <div className="rating_pill">
          <div className="icon_cont">
            <AiFillStar />
          </div>
          <span className="rating">{rating}/10</span>
        </div>
        <button onClick={cardClickHandler}  className="play_now">Play Now</button>
      </div>
    </div>
  );
};

export default CasinoCard;
