import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../redux/userSlice";
import { Routes } from "react-router-dom";
import { AuthRoutes } from "./AuthRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { UserRoute } from "./UserRoute";
const AllRoutes = () => {
  const { user } = useSelector((store) => store.user);

  let isLoggedin = user;
  const dispatch = useDispatch();
  const BJPredictionUserDetails = localStorage.getItem(
    "BJPredictionUserDetails"
  );
  if (BJPredictionUserDetails) {
    if (!isLoggedin) {
      isLoggedin = true;
      dispatch(setUserInfo(JSON.parse(BJPredictionUserDetails)));
    }
  }
  console.log(user, "some");
  return (
    <>
      {user ? (
        <Routes>
          {PublicRoutes()}
          {UserRoute()}
        </Routes>
      ) : (
        <Routes>
          {PublicRoutes()}
          {AuthRoutes()}
        </Routes>
      )}
    </>
  );
};

export default AllRoutes;
