import React from "react";

const Item = ({ label, values }) => {
  return (
    <div className="item">
      <p className="label">{label}</p>
      {values?.map((each) => (
        <p className="value">{each}</p>
      ))}
    </div>
  );
};

export default Item;
