import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./ProfilePage.css";
import { AiFillCloseCircle, AiFillPlusCircle } from "react-icons/ai";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import TextField from "../../Components/Common/Input/TextField";
import GoBackButton from "../../Components/GoBackButton/GoBackButton";
import { setUserInfo } from "../../redux/userSlice";

const ProfilePage = () => {
  const { user } = useSelector((store) => store.user || {});
  const [values, setValues] = useState({
    name: user?.name || "",
    username: user?.username || "",
    email: user?.email || "",
    profile_image: user?.profile_image,
    current_password: "",
    new_password: "",
    mobile: user?.mobile,
  });

  const [disableBtn, setDisableBtn] = useState(true);
  const [updatedImage, setUpdateImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const cb = (success, res) => {
    if (success) {
      toast.success(res?.message || "User Created Successfully");
      navigate("/");
    } else {
      if (res?.errors) {
        setErrors(res?.errors);
        return;
      }
      toast.error(res?.message || "Something went wrong, Try again");
    }
  };

  const dispatch = useDispatch();

  const updateUserRedux = (response) => {
    let update_user = { ...user};
    if (response?.profile_image) {
      update_user.name = response?.name;
      update_user.mobile = response?.mobile;
      update_user.profile_image = response?.profile_image;
      setValues({ ...values, profile_image: response?.profile_image });
    }
    localStorage.setItem('BJPredictionUserDetails', JSON.stringify(update_user))
    dispatch(setUserInfo(update_user));
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("mobile", values.mobile);
    formData.append("current_password", values.current_password);
    formData.append("new_password", values.new_password);
    formData.append("username", values.username);
    if (updatedImage) {
      formData.append("profile_image", updatedImage);
    }
    setLoading(true);
    axios
      .post("/user-profile", formData)
      .then((res) => {
        if (res?.data?.response) {
          let updateValues = {
            ...values,
          };
          if (updatedImage) {
            setValues(updateValues);
            setUpdateImage(null);
          }
          setLoading(false);
           updateUserRedux(res?.data?.data);
          toast.success(res?.data?.message || "Updated Successfully!");
        } else {
          setLoading(false);
          console.log(res.data.errors);
          if (res?.data?.errors) setErrors(res?.data.errors);
          toast.error("Field Errors");
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(
          e?.response?.data?.message || "Something went wrong try again..."
        );
        if (e?.response?.data?.errors) {
          setErrors(e?.response?.data?.errors);
        }
      });
    // alert(JSON.stringify(values));
    // dispatch(profileUpadateAction({ payload: values, cb: cb }));
  };
  const { isLoading } = useSelector((store) => store.user);

  const inputChangeHandler = (e) => {
    if (disableBtn) {
      setDisableBtn(false);
    }
    let name = e.target.name;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const imageChangeHandler = (e) => {
    setDisableBtn(false);
    setUpdateImage(e?.target?.files?.[0]);
  };

  const ProfileImageUrlGenerate = useMemo(() => {
    return updatedImage ? URL.createObjectURL(updatedImage) : false;
  }, [updatedImage]);
  return (
    <div className="profile-page page">
      <GoBackButton title="Profile" />
      <Loader loading={isLoading || loading} />
      <div className="register-page">
        <div className="bottom-cont">
          <form onSubmit={formSubmitHandler}>
            <div className="profile-img-cont mt-25 mb-25">
              {updatedImage || values?.profile_image ? (
                <img
                  className="profile-img"
                  src={
                    updatedImage
                      ? ProfileImageUrlGenerate
                      : values?.profile_image
                  }
                  alt=""
                />
              ) : (
                <img
                  className="profile-img"
                  src={require("../../assets/user.jpg")}
                  alt=""
                />
              )}
              {updatedImage ? (
                <div
                  onClick={() => setUpdateImage(null)}
                  className="profile_update_btn"
                >
                  <AiFillCloseCircle />
                </div>
              ) : (
                <label for="file" className="profile_update_btn">
                  <AiFillPlusCircle />
                </label>
              )}
              <input
                onChange={imageChangeHandler}
                id="file"
                type="file"
                className="profileImageInput"
              />
            </div>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.full_name ? " error" : ""}`}
                name="name"
                label="Full Name"
                type="text"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.full_name?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <TextField
                className={` ${errors?.username ? " error" : ""}`}
                name="username"
                label="Username"
                type="text"
                setValues={setValues}
                values={values}
                onChange={inputChangeHandler}
                disabled
              />
              {errors?.username?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <TextField
                className={` ${errors?.email ? " error" : ""}`}
                name="email"
                label="Email"
                type="email"
                setValues={setValues}
                values={values}
                onChange={inputChangeHandler}
                disabled
              />
              {errors?.email?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            {/* <div className="flex flex-column">
              <TextField
                className={` ${errors?.email ? " error" : ""}`}
                name="current_password"
                label="Current Password"
                type="password"
                setValues={setValues}
                values={values}
                onChange={inputChangeHandler}
              />
              {errors?.current_password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.email ? " error" : ""}`}
                name="new_password"
                label="New Password"
                type="password"
                setValues={setValues}
                values={values}
                onChange={inputChangeHandler}
              />
              {errors?.new_password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div> */}
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.mobile ? " error" : ""}`}
                name="mobile"
                label="Phone"
                type="text"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.mobile?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <button
              disabled={disableBtn}
              className="btn btn-primary update_profile_btn "
            >
              Update Profile
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
