import React from "react";
import "./SectionHeadingWithDescription.scss";

const SectionHeadingWithDescription = ({
  heading,
  description,
  viewAllLabel,
  viewAllClickHandler,
}) => {
  return (
    <div className="section_heading_desc">
      <div className="left">
        <p className="section_label_nrml">{heading}</p>
        {description && (
          <p className="small_light_text">{`( ${description} )`}</p>
        )}
      </div>
      {viewAllLabel && (
        <p onClick={viewAllClickHandler} className="view_all link">
          {viewAllLabel}
        </p>
      )}
    </div>
  );
};

export default SectionHeadingWithDescription;
