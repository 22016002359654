import React from "react";
import { casinoBrandListing } from "./data";
import CasinoCard from "./CasinoCard";
import "./CasinoBrandListingpage.css";
import GoBackButton from "../../Components/GoBackButton/GoBackButton";
import Loader from "../../Components/Loader/Loader";
import UseApiError from "../../Hooks/Common/UseApiError";
import { useDispatch, useSelector } from "react-redux";
import { clearCasinoError, fetchAllCasinos } from "../../redux/casinoSlice";
import { useEffect } from "react";
import { getLatestMonth } from "../../utils/utils";

const CasinoBrandListingPage = () => {
  const dispatch = useDispatch();
  const {
    data: casinoBrandListing,
    isLoading,
    error,
  } = useSelector((store) => store.casino);

  UseApiError(error, clearCasinoError);
  useEffect(() => {
    dispatch(fetchAllCasinos());
  }, []);

  return (
    <div className="page brand_lising_page">
      <Loader loading={isLoading} />
      <GoBackButton />
      <p className="main_title">
        Top Rated Online Casino List Genuine Reviews , Ratings & Rewards
      </p>
      <div className="slider_cont">
        <div className="cards">
          {casinoBrandListing?.top_rated_list?.map((each) => (
            <CasinoCard {...each} />
          ))}
        </div>
      </div>
      <div className="trending_section">
        <p className="main_title">Trending Picks of {getLatestMonth()}</p>
        <div className="cards">
          {casinoBrandListing?.trending_of_month?.map((each) => (
            <CasinoCard {...each} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CasinoBrandListingPage;
