import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./HeaderScoreInfo.scss";
import { toast } from "react-toastify";
import {
  formatDateFunction,
  monthsAgoCalculator,
} from "../../../../utils/utils";

const HeaderScoreInfo = () => {
  const [data, setData] = useState(null);
  // const [intervalRef, setIntervalRef] = useState(null);
  const { id } = useParams();
  const intervalRef = useRef(null);

  const fetchMatchStreamInfo = () => {
    axios
      .get(`/match-stream/${id}`)
      .then((res) => {
        if (
          res.data?.status === "completed" ||
          res.data?.status == "upcoming"
        ) {
          clearInterval(intervalRef.current);
        }
        setData(res.data);
      })
      .catch((e) => {
        clearInterval(intervalRef.current);
        toast.error(
          e?.response?.data?.message || "Failed to get Match Head Info"
        );
      });
  };

  useEffect(() => {
    fetchMatchStreamInfo();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchMatchStreamInfo();
    }, 15000);
    intervalRef.current = intervalId;
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const isUpcoming = data?.status == "upcoming";
  const isCompleted = data?.status == "completed";

  return (
    <div className="header_score_info">
      {data && (
        <>
          <div className="vs_cont">
            <div className="team">
              <img src={data?.teamA?.logo_url} alt="" className="team_logo" />
              <div className="score_info">
                <p className="team_title">{data?.teamA?.short_name}</p>
                {!isUpcoming && (
                  <p className="scores_info_data">
                    <span className="score">{data?.teamA?.scores}</span>
                    <span className="overs">( {data?.teamA?.overs} )</span>
                  </p>
                )}
              </div>
            </div>
            {isUpcoming && <p>{monthsAgoCalculator(data?.startDateTime)}</p>}
            <div className="team right">
              <div className="score_info">
                <p className="team_title">{data?.teamB?.short_name}</p>
                {!isUpcoming && (
                  <p className="scores_info_data">
                    <span className="score">{data?.teamB?.scores}</span>
                    <span className="overs">( {data?.teamB?.overs} )</span>
                  </p>
                )}
              </div>
              <img src={data?.teamB?.logo_url} alt="" className="team_logo" />
            </div>
          </div>
          {data.scores && (
            <div className="score_cont">
              <div className="left">
                <p>CRR : {data?.scores?.CRR}</p>
                {/* <p>RRR : {data?.scores?.RRR || 0}</p> */}
              </div>
              <div className="right">
                {data?.scores?.target && (
                  <p>Target : {data?.scores?.target || 0}</p>
                )}
              </div>
            </div>
          )}
          <p className="info_message">
            {data?.result || data?.session?.session || ""}
          </p>
        </>
      )}
    </div>
  );
};

export default HeaderScoreInfo;
