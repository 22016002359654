import React from "react";
import "./ShowAllPopup.scss";
import { IoMdClose } from "react-icons/io";
import Modal from "../../../../../Components/Modal/Modal";

const ShowAllPopup = ({ fantacyPoints, closer }) => {
  return (
    <div className="players_list_popup">
      <Modal show closeModal={closer} className="player_list_modal">
        <div className="player_list_header">
          <p onClick={closer} className="close_btn">
            <IoMdClose />
          </p>
        </div>
        <div className="flex justify-between mb-15">
          <p className="font-small text-light">Player</p>
          <p className="font-small text-light">Points</p>
        </div>
        <div className="fantacy_rows">
          {fantacyPoints?.map((each) => (
            <div className="player">
              <div className="left">
                <img src={each?.player_image} alt="" />
                <div className="right">
                  <p className="player_name">{each?.player_name}</p>
                  <p className="specification">{`${each?.role} | ${each?.country}`}</p>
                </div>
              </div>
              <p className="points">{each?.points}</p>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default ShowAllPopup;
