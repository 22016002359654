import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  live: null,
  forYou: null,
  upcoming: null,
  finished: null,
  isLoading: false,
  error: null,
};

export const fetchLiveMatches = createAsyncThunk(
  "fetchLive",
  (payload, { rejectWithValue }) => {
    return axios.get(`/live`).then((res) => res.data);
  }
);
export const FetchForYouMatches = createAsyncThunk(
  "fetchForYou",
  (payload, { rejectWithValue }) => {
    return axios.get(`/forYou`).then((res) => res.data);
  }
);
export const fetchUpcomingMatches = createAsyncThunk(
  "fetchUpcoming",
  (payload, { rejectWithValue }) => {
    return axios.get(`/upcoming`).then((res) => res.data);
  }
);
export const fetchFinishedMatches = createAsyncThunk(
  "fetchFinished",
  (payload, { rejectWithValue }) => {
    return axios.get(`/finished`).then((res) => res.data);
  }
);

export const matchesSlice = createSlice({
  name: "matches",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //////////// FETCH lIVE MATCHES ////////////////
    builder.addCase(fetchLiveMatches.pending, (state) => {
      if (!state.live) state.isLoading = true;
    });
    builder.addCase(fetchLiveMatches.fulfilled, (state, action) => {
      state.isLoading = false;
      state.live = action.payload;
    });
    builder.addCase(fetchLiveMatches.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      toast.error(action.error?.message || "Failed to fetch data , try again");
    });

    //////////// FETCH FOR YOU ////////////////
    builder.addCase(FetchForYouMatches.pending, (state) => {
      if (!state.forYou) state.isLoading = true;
    });
    builder.addCase(FetchForYouMatches.fulfilled, (state, action) => {
      state.isLoading = false;
      state.forYou = action.payload;
    });
    builder.addCase(FetchForYouMatches.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      toast.error(action.error?.message || "Failed to fetch data , try again");
    });

    //////////// FETCH UPCOMING ////////////////
    builder.addCase(fetchUpcomingMatches.pending, (state) => {
      if (!state.upcoming) state.isLoading = true;
    });
    builder.addCase(fetchUpcomingMatches.fulfilled, (state, action) => {
      state.isLoading = false;
      state.upcoming = action.payload;
    });
    builder.addCase(fetchUpcomingMatches.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      toast.error(action.error?.message || "Failed to fetch data , try again");
    });

    //////////// FETCH FINISHED ////////////////
    builder.addCase(fetchFinishedMatches.pending, (state) => {
      if (!state.finished) state.isLoading = true;
    });
    builder.addCase(fetchFinishedMatches.fulfilled, (state, action) => {
      state.isLoading = false;
      state.finished = action.payload;
    });
    builder.addCase(fetchFinishedMatches.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      toast.error(action.error?.message || "Failed to fetch data , try again");
    });
  },
});

// SELECTORS
export const selectLiveData = (state) => state.matches?.live;
export const selectForYouData = (state) => state.matches?.forYou;
export const selectUpcomingData = (state) => {
  let upcomingMatches = state.matches?.upcoming?.upcoming;
  let groupedData = groupMatchesByDate(upcomingMatches);
  return groupedData;
};
export const selectFinishedData = (state) => {
  let finishedMatches = state.matches?.finished?.finished;
  let groupedData = groupMatchesByDate(finishedMatches);
  return groupedData;
};
export const selectMatchesLoader = (state) => state.matches?.isLoading;

const groupMatchesByDate = (data) => {
  if (!data) return data;
  const groupedMatches = {};
  data.forEach((match) => {
    const matchDate = new Date(match.date_start_ist).toLocaleDateString(
      "en-US"
    );
    if (!groupedMatches[matchDate]) {
      groupedMatches[matchDate] = [];
    }
    groupedMatches[matchDate].push(match);
  });
  return groupedMatches;
};

export default matchesSlice.reducer;
