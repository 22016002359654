import { Route, Navigate } from "react-router-dom";
import LoginPage from "../pages/LoginPage/LoginPage";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import RegisterPage from "../pages/RegisterPage/RegisterPage";
import ResetPassword from "../pages/ResetPassword/ResetPassword";

export const AuthRoutes = () => {
  return [
    <Route path="/signin" element={<LoginPage />} />,
    <Route path="/register" element={<RegisterPage />} />,
    <Route path="/forgot-password" element={<ForgotPassword />} />,
    <Route path="/reset" element={<ResetPassword />} />,
    <Route path="*" element={<Navigate to="/signin" replace />} />,
  ];
};

