import { IoIosArrowRoundDown } from "react-icons/io";



export const ScoreCardTabs = [
    {
        label: "Team A",
        key: 1,
        isActive: true
    },
    {
        label: "Team B",
        key: 2 
    },

]

export const scoreCardTableColumns = [
    {
        header: "Batter",
        icon: <IoIosArrowRoundDown />,
        accessor: "name",
        sub_heading: "how_out",
        type: 'flex-1 bold align-center blue pl-10'
    },
    {
        header: "R",
        accessor: "runs",
        type: "bold"
    },
    {
        header: "B",
        accessor: "balls_faced"
    },
    {
        header: "4s",
        accessor: "fours"
    },
    {
        header: "6s",
        accessor: "sixes"
    },
    {
        header: "SR",
        accessor: "strike_rate",
        type: 'pr-10'
    },
]

export const scoreCardData = [
    {
        name: "D Warner",
        runs: 7,
        balls: 3,
        fours: 1,
        sixes: 0,
        strike_rate: 233.33,
        out_type: "c Bairstow b Broad"
    },
    {
        name: "S Smith",
        runs: 45,
        balls: 28,
        fours: 6,
        sixes: 1,
        strike_rate: 160.71,
        out_type: "lbw b Woakes"
    },
    {
        name: "M Labuschagne",
        runs: 22,
        balls: 17,
        fours: 3,
        sixes: 0,
        strike_rate: 129.41,
        out_type: "c Root b Leach"
    },
    {
        name: "G Maxwell",
        runs: 30,
        balls: 14,
        fours: 2,
        sixes: 2,
        strike_rate: 214.29,
        out_type: "c Bairstow b Woakes"
    },
    {
        name: "A Finch",
        runs: 18,
        balls: 12,
        fours: 2,
        sixes: 0,
        strike_rate: 150.00,
        out_type: "c Root b Leach"
    },
    {
        name: "M Wade",
        runs: 12,
        balls: 9,
        fours: 1,
        sixes: 0,
        strike_rate: 133.33,
        out_type: "Not out"
    },
    {
        name: "P Cummins",
        runs: 8,
        balls: 5,
        fours: 1,
        sixes: 0,
        strike_rate: 160.00,
        out_type: "Not out"
    }
];


export const scoreCardBowlerColumns = [
    {
        header: "Bowler",
        icon: <IoIosArrowRoundDown />,
        accessor: "name",
        type: 'flex-1 bold align-center blue pl-10'
    },
    {
        header: "O",
        accessor: "overs",
        type: "bold"
    },
    {
        header: "M",
        accessor: "maidens"
    },
    {
        header: "R",
        accessor: "runs_conceded"
    },
    {
        header: "W",
        accessor: "wickets"
    },
    {
        header: "Eco",
        accessor: "econ",
        type: 'pr-10'
    },
]
export const fallOfWicketsColumns = [
    {
        header: "Batter",
        accessor: "name",
        type: 'flex-1 bold align-center blue pl-10 bold'
    },
    {
        header: "Score",
        accessor: "score",
        type: "bold"
    },
    {
        header: "Over",
        accessor: "over"
    },

]

export const fallOfWicketsColumnsData = [
    {
        name: "J Anderson",
        score: "1-16",
        over : 1.1
    },
    {
        name: "J Anderson 1",
        score: "1-18",
        over : 1.1
    },
    {
        name: "J Anderson 3",
        score: "1-16",
        over : 1.5
    },
]

export const scoreCardBowlerData = [
    {
        name: "J Anderson",
        Overs: 4,
        m: 0,
        runs: 20,
        wickets: 1,
        Eco: 5.00,
    },
    {
        name: "J Archer",
        Overs: 3,
        m: 0,
        runs: 15,
        wickets: 1,
        Eco: 5.00,
    },
    {
        name: "S Broad",
        Overs: 4,
        m: 0,
        runs: 30,
        wickets: 2,
        Eco: 7.50,
    },
    {
        name: "M Wood",
        Overs: 3,
        m: 0,
        runs: 22,
        wickets: 2,
        Eco: 7.33,
    },
    {
        name: "C Woakes",
        Overs: 2,
        m: 0,
        runs: 10,
        wickets: 0,
        Eco: 5.00,
    },
    {
        name: "J Leach",
        Overs: 3,
        m: 0,
        runs: 18,
        wickets: 0,
        Eco: 6.00,
    },
];




export const team_a_players = [
    {
        player_name: "E Villani (c)",
        specification: "SR: 94.08",
        image: "https://picsum.photos/200/200"
    },
    {
        player_name: "E Villani (wk)",
        specification: "SR: 94.08",
        image: "https://picsum.photos/201/201",
        is_outer_country: true
    },
    {
        player_name: "B Smith",
        specification: "SR: 94.08",
        image: "https://picsum.photos/202/202",
        is_outer_country: true
    },
    {
        player_name: "G Graham",
        specification: "SR: 94.08",
        image: "https://picsum.photos/202/202",
    },
    {
        player_name: "G Graham",
        specification: "SR: 94.08",
        image: "https://picsum.photos/202/202",
    },

]


