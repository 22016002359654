export const fantacyPointsData = [
    {
        country: 'AUS',
        player_name: "T Head",
        player_image: "https://picsum.photos/100/100",
        points: "180.0",
        role: "Batter"
    },
    {
        country: 'Ind',
        player_name: "Lokesh ",
        player_image: "https://picsum.photos/98/98",
        points: "170.0",
        role: "Batter"

    },
    {
        country: 'AUS',
        player_name: "Virat Kohli",
        player_image: "https://picsum.photos/97/97",
        points: "163.0",
        role: "Batter"

    },
    {
        country: 'AUS',
        player_name: "Cummins",
        player_image: "https://picsum.photos/96/96",
        points: "162.0",
        role: "Batter"

    },

]

export const allFantacyData = [
    ...fantacyPointsData,
    ...fantacyPointsData
]


export const FANTACY_POINTS = "Fantacy Points"
export const MOST_RUNS = "Most Runs"
export const MOST_WICKETS = "Most Wickets"
export const MOST_SIXES = "Most Sixes"

export const playerStatsTabs = [
    {
        label: FANTACY_POINTS,
        key: FANTACY_POINTS
    },
    {
        label: MOST_RUNS,
        key: MOST_RUNS
    },
    {
        label: MOST_WICKETS,
        key: MOST_WICKETS
    },
    {
        label: MOST_SIXES,
        key: MOST_SIXES
    },
]