import React, { useEffect } from "react";
import "./InfoPage.scss";
import { keys, liveData, team_a_form, team_b_form } from "./data";
import { IoMdCalendar } from "react-icons/io";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOndemandVideo } from "react-icons/md";
import PlayersViewButton from "./PlayersViewButton";
import TeamForm from "./TeamForm/TeamForm";
import SectionHeadingWithDescription from "./SectionHeadingWithDescription/SectionHeadingWithDescription";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInfoData,
  selectMatchDetails,
  selectMatchInfoData,
  slectInfoLoader,
} from "../../../../redux/matchDetailSlice";
import { useParams } from "react-router-dom";
import { formatDateFunction } from "../../../../utils/utils";
import FinishedPage from "../../Components/UpcomingGroup/FinishedMatch";
import Loader from "../../../../Components/Loader/Loader";
import TeamComparision from "./TeamComparision/TeamComparision";
import Umpires from "./Umpires/Umpires";
import NoDataFound from "../../../../Components/NoDataFound/NoDataFound";

const InfoPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchInfoData(id));
  }, []);
  const infoData = useSelector(selectMatchInfoData);
  const matchDetails = useSelector(selectMatchDetails);

  console.log(infoData,'info data');
  return (
    <div className="info_page">
      {matchDetails?.is_info_no_data ? (
        <NoDataFound />
      ) : (
        <>
          <div className="series_detail_cont border_bottom">
            <div className="left">
              <p className="small_light_text">
                {infoData?.match?.matdch_no || ""}
              </p>
              <p className="large_dark_text">{`${infoData?.match?.abbr || ""} ${
                infoData?.match?.season || ""
              }`}</p>
            </div>
            <div className="series_logo">
              {/* <img src={liveData.series_image} alt="" /> */}
            </div>
          </div>
          <div className="content p-10">
            <div className="time_location_stream_cont">
              <div className="match_starts_at item">
                <div className="icon_cont icon_small">
                  <IoMdCalendar />
                </div>
                <p className="small_text">
                  {formatDateFunction(
                    infoData?.[keys.date_location]?.startDateTime
                  )}
                </p>
              </div>
              <div className="match_location_at item">
                <div className="icon_cont icon_small">
                  <FaMapMarkerAlt />
                </div>
                <p className="small_text link">{`${
                  infoData?.[keys.date_location]?.venue?.name || ""
                } , ${
                  infoData?.[keys.date_location]?.venue?.location || ""
                } , ${
                  infoData?.[keys.date_location]?.venue?.country || ""
                }`}</p>
              </div>
              {/* <div className="match_stream_at item">
              <div className="icon_cont icon_small">
                <MdOndemandVideo />
              </div>
              <p className="small_text ">{""}</p>
            </div> */}
            </div>

            <div className="teams_cont">
              <p className="section_label_nrml">Playing X1</p>
              <PlayersViewButton
                logo={infoData?.[keys.sqad]?.teamA?.logo}
                team_name={infoData?.[keys.sqad]?.teamA?.title}
                players={infoData?.[keys.sqad]?.teamA?.players}
              />
              <PlayersViewButton
                logo={infoData?.[keys.sqad]?.teamB?.logo}
                team_name={infoData?.[keys.sqad]?.teamB?.title}
                players={infoData?.[keys.sqad]?.teamB?.players}
              />
            </div>

            <div className="team_form_cont mt-30">
              <SectionHeadingWithDescription
                heading="Team form"
                description="Last 5 matches"
              />
              <TeamForm
                team_data={infoData?.[keys.teams_with_logo]?.teamA}
                data={infoData?.[keys.team_form]?.TeamA}
              />
              <TeamForm
                team_data={infoData?.[keys.teams_with_logo]?.teamB}
                data={infoData?.[keys.team_form]?.TeamB}
              />
            </div>
            <div className="head_to_head_cont mt-30 upcoming_group">
              <SectionHeadingWithDescription
                heading="Head to Head"
                description="Last 10 matches"
                // viewAllLabel="All Matches"
              />
              {infoData?.[keys.h2h]?.["match-details"]?.map((each) => (
                <FinishedPage
                  data={each}
                  key={each?.match_id}
                  id={each?.match_id}
                  is_live
                  label={`${each?.subtitle} ${each?.format_str} , ${each?.competition?.title} ${each?.competition?.season}`}
                  eventStatus={each?.status_note}
                  match_1_info={{
                    logo: each?.teama?.logo_url,
                    short_name: each?.teama?.name,
                    is_batting: false,
                    score_wickets: each?.teama?.scores,
                    overs: each?.teama?.overs,
                  }}
                  match_2_info={{
                    logo: each?.teamb?.logo_url,
                    short_name: each?.teamb?.name,
                    is_batting: false,
                    score_wickets: each?.teamb?.scores,
                    overs: each?.teamb?.overs,
                  }}
                />
              ))}
            </div>
            <div className=" mt-30">
              <SectionHeadingWithDescription
                heading="Team Comparision"
                description="Last 10 matches"
              />
              <TeamComparision
                teama_logo={infoData?.[keys.sqad]?.teamA?.logo}
                teama_name={infoData?.[keys.sqad]?.teamA?.title}
                teamb_logo={infoData?.[keys.sqad]?.teamB?.logo}
                teamb_name={infoData?.[keys.sqad]?.teamB?.title}
                data={infoData?.[keys.team_comparison]}
              />
            </div>
            <div className=" mt-30">
              <SectionHeadingWithDescription heading="Umpires" />
              <div className="mt-10">
                <Umpires data={infoData?.[keys.umpires]} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InfoPage;
