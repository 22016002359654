import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  data: null,
  info: null,
  is_info_no_data: false,
  fantasy: null,
  live: null,
  is_live_no_data : false,
  scorecard: null,
  is_scorecard_no_data: false,
  pointsTable: null,
  is_points_table_no_data: false,
  isLoading: false,
  error: null,
};

export const fetchInfoData = createAsyncThunk(
  "fetchInfoData",
  (match_id, { rejectWithValue }) => {
    return axios
      .get(`/infopage/${match_id}`)
      .then((res) => ({ ...res.data, match_id }));
  }
);
export const fetchScoreCardData = createAsyncThunk(
  "fetchScoreCardData",
  (match_id, { rejectWithValue }) => {
    return axios.get(`/scorecardpage/${match_id}`).then((res) => res.data);
  }
);
export const fetchPointsTable = createAsyncThunk(
  "fetchPointsTable",
  (match_id, { rejectWithValue }) => {
    return axios.get(`/pointstable/${match_id}`).then((res) => res.data);
  }
);
export const fetchLiveSectionData = createAsyncThunk(
  "fetchLiveSection",
  (match_id, { rejectWithValue }) => {
    return axios.get(`/livepage/${match_id}`).then((res) => res.data);
  }
);

export const matchDetailSlice = createSlice({
  name: "matches",
  initialState,
  reducers: {
    clearAllmatchDetails: (state, action) => {
      state.data = null;
      state.info = null;
      state.fantasy = null;
      state.live = null;
      state.scorecard = null;
      state.pointsTable = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    //////////////////////// INFO DATA ////////////////////////
    builder.addCase(fetchInfoData.pending, (state, action) => {
      if (action.meta.arg != state.info?.match_id) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchInfoData.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.message == "No Data Found") {
        state.is_info_no_data = true;
        return;
      }
      state.info = action.payload;
      state.data = action.payload.match;
      state.is_info_no_data = false;
    });
    builder.addCase(fetchInfoData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      toast.error(action.error?.message || "Failed to fetch info , try again");
    });
    //////////////////////////////////////////////////////////////////
    //////////////////////// SCORE CARD DATA ////////////////////////
    builder.addCase(fetchScoreCardData.pending, (state) => {
      if (!state.scorecard) state.isLoading = true;
    });
    builder.addCase(fetchScoreCardData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.scorecard = action.payload;
    });
    builder.addCase(fetchScoreCardData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //////////////////////////////////////////////////////////////////
    //////////////////////// POINTS TABLE DATA ////////////////////////
    builder.addCase(fetchPointsTable.pending, (state) => {
      if (!state.pointsTable) state.isLoading = true;
    });
    builder.addCase(fetchPointsTable.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload === "No data Found"||action.payload.message === "No data found") {
        state.is_points_table_no_data = true;
        return;
      }
      state.pointsTable = action.payload;
      state.is_points_table_no_data = false;
    });
    builder.addCase(fetchPointsTable.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //////////////////////////////////////////////////////////////////
    //////////////////////// Live section  DATA ////////////////////////
    builder.addCase(fetchLiveSectionData.pending, (state) => {
      if (!state.live) state.isLoading = true;
    });
    builder.addCase(fetchLiveSectionData.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload === "No data Found"||action.payload.message === "No data found") {
        state.is_live_no_data = true;
        return;
      }
      state.live = action.payload;
      state.is_points_table_no_data = false;
    });
    builder.addCase(fetchLiveSectionData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //////////////////////////////////////////////////////////////////
  },
});

// SELECTORS
export const selectMatchDetails = (state) => state.matchDetail;
export const selectMatchInfoData = (state) => state.matchDetail?.info;
export const selectMatchLiveData = (state) => state.matchDetail?.live;
export const slectInfoLoader = (state) => state.matchDetail?.isLoading;
export const selectMatchDetailLoader = (state) => state.matchDetail?.isLoading;
export const selectScoreCardData = (state) => state.matchDetail?.scorecard;
export const selectPointsTableData = (state) => state.matchDetail?.pointsTable;

export const { clearAllmatchDetails } = matchDetailSlice.actions;

export default matchDetailSlice.reducer;
