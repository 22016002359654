import React from "react";
import "./OverList.scss";

const OverList = ({ runs, over }) => {
  return (
    <div className="over_runs">
      <p className="overs_label">Over{over}</p>
      {runs?.map((each) => (
        <p className={`run A${each.score}`}>{each.score}</p>
      ))}
    </div>
  );
};

export default OverList;
