import React from "react";
import { assets } from "../../assets/assets";
import './NoDataFound.scss'

const NoDataFound = () => {
  return (
    <div className="no_data_cont">
      <img src={assets.images.no_data} alt="" />
    </div>
  );
};

export default NoDataFound;
