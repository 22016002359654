

export const RECOMMEND_TYPES = {
    BLOG: 'BLOG',
    CATEGORY: "CATEGORY"
}

export const defaultCategoriesInBlogs = [
    { id: 11, name: 'All' },
]
export const defaultCategoriesInDetails = [
    { id: 11, name: 'All' },
    { id: 12, name: 'Custom' },
]

export const blogsData = {
    blogs: [
        {
            id: 1,
            "banner_image": "https://images.unsplash.com/photo-1624194770831-00d655f9a1e4?auto=format&fit=crop&w=800",
            "posted_by_image": "https://images.unsplash.com/photo-1663124178598-71717cdea439?auto=format&fit=crop&w=200",
            "posted_by_name": "Cricket News",
            "posted_at": "2023-07-08",
        },
        {
            id: 1,

            "banner_image": "https://images.unsplash.com/photo-1624194770831-00d655f9a1e4?auto=format&fit=crop&w=800",
            "posted_by_image": "https://images.unsplash.com/photo-1663124178598-71717cdea439?auto=format&fit=crop&w=200",
            "posted_by_name": "Cricket News",
            "posted_at": "2023-07-08",
        },
        {
            id: 1,

            "banner_image": "https://images.unsplash.com/photo-1624194770831-00d655f9a1e4?auto=format&fit=crop&w=800",
            "posted_by_image": "https://images.unsplash.com/photo-1663124178598-71717cdea439?auto=format&fit=crop&w=200",
            "posted_by_name": "Cricket News",
            "posted_at": "2023-07-08",
        },
    ],
    trending_categories: [
        {
            "title": "Business Related News",
            "category_id": 1
        },
        {
            "title": "Cricket News",
            "category_id": 2
        },
        {
            "title": "Sports Highlights",
            "category_id": 3
        },
        {
            "title": "Player Interviews",
            "category_id": 4
        },
        {
            "title": "Match Previews",
            "category_id": 5
        },
        {
            "title": "Fantasy Cricket",
            "category_id": 6
        }
    ]
}


export const ViewPostData = {

    "banner_image": "https://images.unsplash.com/photo-1624194770831-00d655f9a1e4?auto=format&fit=crop&w=800",
    "posted_by_image": "https://images.unsplash.com/photo-1663124178598-71717cdea439?auto=format&fit=crop&w=200",
    "posted_by_name": "Cricket News",
    "posted_at": "2023-07-08",
    "html_blog_string": `<div>
      <h2>Virat Kohli's Recent Performance</h2>
    
      <h3>Outstanding Batting Performance</h3>
      <p>Virat Kohli showcased an outstanding batting performance in the recent cricket match. He displayed impeccable technique and an array of shots that left the opposition bowlers struggling. His ability to read the game, adapt to different situations, and find gaps in the field was remarkable. Kohli's flawless footwork and timing allowed him to score runs consistently and put pressure on the opposition.</p>
      
      <h3>Leadership on the Field</h3>
      <p>Not only did Virat Kohli excel with the bat, but he also demonstrated remarkable leadership skills on the field. He led by example, constantly encouraging and motivating his teammates. Kohli's strategic field placements and quick decision-making played a pivotal role in putting the opposition under pressure. His ability to maintain a calm and composed demeanor, even in tense situations, showcased his leadership qualities.</p>
      
      <h3>Match-Winning Century</h3>
      <p>In a crucial moment of the match, Virat Kohli played a match-winning innings by scoring a spectacular century. His innings was a masterclass in batting, filled with elegant shots, powerful drives, and precise placements. Kohli's ability to rotate the strike and maintain a high scoring rate was exceptional. He took calculated risks at the right moments, capitalizing on the opposition's weaknesses and ensuring victory for his team.</p>
      
      <h3>Impactful Contributions</h3>
      <p>Virat Kohli's impactful contributions extended beyond his batting prowess
    `
    ,
    related_articles: [
        {
            "title": "Top Batsmen in the World",
            "post_id": "12345"
        },
        {
            "title": "Cricket Team Rankings Update",
            "post_id": "67890"
        }
    ],
    trending_categories: [
        {
            "title": "Business Related News",
            "category_id": 1,
        },
        {
            "title": "Cricket News",
            "category_id": 2
        },
        {
            "title": "Sports Highlights",
            "category_id": 3
        },
        {
            "title": "Player Interviews",
            "category_id": 4
        },
        {
            "title": "Match Previews",
            "category_id": 5
        },
        {
            "title": "Fantasy Cricket",
            "category_id": 6
        }
    ]
}

