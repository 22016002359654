import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { clearFieldErrors, signupUser } from "../../redux/userSlice";
import { toast } from "react-toastify";
import "./RegisterPage.css";
import Select from "react-select";
import { country_names } from "./data";
import { removeInvaildCharFromMobileField } from "../../utils/utils";
import { useEffect } from "react";
import Loader from "../../Components/Loader/Loader";
import TextField from "../../Components/Common/Input/TextField";

const RegisterPage = () => {
  const [values, setValues] = useState({
    country_code: { label: "+91", value: "+91" },
  });
  const [errors, setErrors] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formSubmitHandler = (e) => {
    e.preventDefault();
    let payload = { ...values };
    if (payload.password !== payload.confirm_password) {
      setErrors({
        ...errors,
        confirm_password: ["Password and Confirm password not matched"],
      });
      return;
    }
    payload.mobile = parseInt(values.mobile);
    payload.country_code = values?.country_code?.value;
    dispatch(signupUser(payload));
  };

  const { isLoading, error, field_errors } = useSelector((store) => store.user);

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "mobile") {
      value = removeInvaildCharFromMobileField(value);
      if (value?.length > 13) return;
    }
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    if (errors?.confirm_password && name === "confirm_password") {
      if (values.password == value) {
        setErrors({
          ...errors,
          confirm_password: null,
        });
      } else {
        setErrors({
          ...errors,
          confirm_password: ["Password and Confirm password not matched"],
        });
      }
    }
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    if (error && field_errors) {
      console.log(field_errors, "field errors");
      setErrors(field_errors);
      toast.error(error);
      dispatch(clearFieldErrors());
    }
  }, [error]);

  useEffect(() => {
    let update = country_names?.map((each) => {
      let label = each.dial_code;
      return { label: label, value: label };
    });
    setCountryOptions(update);
  }, []);

  console.log(errors);
  return (
    <div className="page">
      <Loader loading={isLoading} />
      <div className="register-page">
        <img
          src={require("../../assets/logo.png")}
          alt=""
          onClick={() => navigate("/")}
          className="bonus_logo"
        />
        <div className="bottom-cont ">
          <p className="form-heading text-center">Sign in to your account</p>
          <form onSubmit={formSubmitHandler}>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.full_name ? " error" : ""}`}
                name="name"
                label="Full Name"
                type="text"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.full_name?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <TextField
                className={` ${errors?.email ? " error" : ""}`}
                name="email"
                label="Email"
                type="email"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.email?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <TextField
                className={` ${errors?.user_name ? " error" : ""}`}
                name="username"
                label="Username"
                type="text"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.username?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <TextField
                className={` ${errors?.password ? " error" : ""}`}
                name="password"
                label="Password"
                type="password"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.password ? " error" : ""}`}
                name="confirm_password"
                label="Confirm Password"
                type="password"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.confirm_password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <div className="flex flex-column">
              <div className="flex country-input-cont">
                <div className="country-field">
                  <Select
                    classNamePrefix={"country-field"}
                    value={values?.country_code}
                    options={countryOptions}
                    onChange={(val) => {
                      setValues({ ...values, country_code: val });
                    }}
                  />
                </div>
                <div className="flex-1">
                  <TextField
                    className={` ${errors?.mobile ? " error" : ""}`}
                    name="mobile"
                    label="Phone"
                    type="text"
                    setValues={setValues}
                    values={values}
                    required
                    onChange={inputChangeHandler}
                  />
                </div>
              </div>
              {errors?.mobile?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
              {errors?.country_code?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <button className="btn btn-primary">Register</button>
          </form>
          <div className=" sign-up-cont ">
            <div className=" p-cont flex align-center justify-center">
              <p className="mr-5 text-light">Don't Have Account?</p>
              <Link className="f-15 w-600" to={`/signin`}>
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
