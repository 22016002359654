import React, { useMemo } from "react";
import { defaultCategoriesInDetails, defaultCategoriesInBlogs } from "./data";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCategoryError,
  fetchCategories,
} from "../../redux/categoriesSlice";
import UseApiError from "../../Hooks/Common/UseApiError";

const CategoriesCont = ({ isBlogDetail }) => {
  const { search, pathname } = useLocation();
  const currentCategory = useMemo(() => {
    if (!search) return 12;
    let queryParams = new URLSearchParams(search);
    let categoryId = queryParams.get("category");
    return categoryId;
  }, [search]);
  const navigate = useNavigate();
  const categoryClickHandler = (category) => {
    navigate(`/blogs?category=${category.id}`);
  };

  const { isLoading, data, error } = useSelector((store) => store.categories);
  UseApiError(error, clearCategoryError);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);
  return (
    <div className="categories_root_cont">
      <div className="categories">
        {(isBlogDetail ? defaultCategoriesInDetails : defaultCategoriesInBlogs)?.map(
          (each) => (
            <p
              onClick={() => categoryClickHandler(each)}
              key={each.id}
              className={`pill ${each.id == currentCategory && " active"}`}
            >
              {each.name || ""}
            </p>
          )
        )}
        {data?.map((each) => (
          <p
            onClick={() => categoryClickHandler(each)}
            key={each.id}
            className={`pill ${each.id == currentCategory && " active"}`}
          >
            {each.name || ""}
          </p>
        ))}
      </div>
    </div>
  );
};

export default CategoriesCont;
