import React from "react";

const Table = ({ data, columns, innings }) => {
  return (
    <table className="table scorecard">
      <thead>
        <tr>
          {columns?.map((each) => (
            <th className={each.type}>
              {each.header}
              {each?.icon && <span className="icon">{each?.icon}</span>}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((each) => (
          <tr>
            {columns?.map((column, i) => (
              <td className={`${column.type} a${i}`}>
                {column?.data_left_icon && (
                  <span className="data_left_icon">
                    <img
                      className={column?.data_left_icon}
                      src={each?.[column?.data_left_icon]}
                      alt=""
                    />
                  </span>
                )}
                {each?.[column?.accessor]}
                {column.sub_heading && (
                  <span className="sub_heading">
                    {each?.[column?.sub_heading]}
                  </span>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
