import React from "react";
import { FiChevronLeft ,FiSearch } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDeferredValue } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import { fetchAllBlogs } from "../../redux/blogsSlice";

const Header = () => {
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const backClickHandler = () => {
    navigate("/");
  };
  const { isLoading } = useSelector((store) => store.blogs);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const currentCategory = useMemo(() => {
    if (!search) return "";
    let queryParams = new URLSearchParams(search);
    let categoryId = queryParams.get("category");
    if (categoryId == "11") {
      return "";
    }
    return categoryId;
  }, [search]);

  const blogSearchHandler = () => {
    dispatch(
      fetchAllBlogs(`?category=${currentCategory || ""}&title=${searchValue}`)
    );
  };

  return (
    <div className="blog_header">
      <button onClick={backClickHandler} className="back_icon">
        <FiChevronLeft />
      </button>
      <input
        onChange={(e) => setSearchValue(e.target.value || "")}
        value={searchValue || ""}
        placeholder="New theory of Business"
        type="text"
        className="searchBar"
      />
      <button
        disabled={isLoading}
        onClick={blogSearchHandler}
        className="search"
      >
        <IoSearch />
      </button>
    </div>
  );
};

export default Header;
