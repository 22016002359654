import React, { useEffect, useMemo, useRef } from "react";
import "./LivePage.scss";
import { batterColumns, bowlerColumns, liveScore_data } from "./data";
import OverList from "./OverList/OverList";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchLiveSectionData,
  selectMatchDetails,
  selectMatchLiveData,
} from "../../../../redux/matchDetailSlice";
import NoDataFound from "../../../../Components/NoDataFound/NoDataFound";
import Table from "../ScoreCardPage/Table";
import BatterTable from "./BatterTable";

const LivePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchLiveSectionData(id));
  }, []);
  const liveData = useSelector(selectMatchLiveData);
  const matchDetails = useSelector(selectMatchDetails);
  const scoreRef = useRef(null);

  const groupBallbyBall = useMemo(() => {
    let cont = document.getElementsByClassName("overs_data");
    if (cont?.length) {
      let ele = cont[0];
      ele.scrollLeft = ele.scrollWidth;
    }
    return groupDataByOver(liveData?.ball_by_ball);
  }, [liveData]);

  const isUpcoming = liveData?.status == "upcoming";

  return (
    <div className="live_page">
      {matchDetails.is_live_no_data && <NoDataFound />}
      {isUpcoming && (
        <p className="no_data_msg p-10">Match Not started Yet pls stay tuned</p>
      )}
      {!matchDetails?.is_live_no_data && !isUpcoming && (
        <div className="live_runs_cont rtl overflow-x-auto">
          <div ref={scoreRef} className="overs_data">
            {Object.keys(groupBallbyBall)?.map((over) => (
              <OverList over={over} runs={groupBallbyBall[over]} />
            ))}

            {/* {liveScore_data?.overs?.map((each) => (
              <OverList over={each.over} runs={each.score} />
            ))} */}
          </div>
          {liveData?.Batter && (
            <div className="card">
              <BatterTable data={liveData?.Batter} columns={batterColumns} />
              <div className="partner_ship">
                <p>
                  Partnership :{" "}
                  <span className="bold">
                    {liveData?.partnership?.runs} (
                    {liveData?.partnership?.balls}){" "}
                  </span>
                </p>
              </div>
            </div>
          )}
          {liveData?.bowlers && (
            <div className="card">
              <Table data={[liveData?.bowlers]} columns={bowlerColumns} />
            </div>
          )}
        </div>
      )}

      <div className="card p-20">
      <p className="section_label_nrml ">Man of the Match</p>
        {liveData?.man_of_the_match &&
          liveData?.man_of_the_match?.map((each) => (
            <div className="man_of_the_match mt-20">
              <p className="title">{each?.name}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default LivePage;

function groupDataByOver(data) {
  const groupedData = {};

  data?.forEach((item) => {
    const over = item.over;
    if (!groupedData[over]) {
      groupedData[over] = [];
    }
    groupedData[over].push(item);
  });

  return groupedData;
}
