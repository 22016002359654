import React from "react";
import "./LiveStatus.scss";

const LiveStatus = () => {
  return (
    <div className="live_status">
      <div className="round_outer">
        <div className="round_inner"></div>
      </div>
      <p className="live_text">Live</p>
    </div>
  );
};

export default LiveStatus;
