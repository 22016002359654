import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearFieldErrors, signInUser } from "../../redux/userSlice";
import "./LoginPage.css";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import TextField from "../../Components/Common/Input/TextField";
import { useEffect } from "react";

const LoginPage = () => {
  const [values, setValues] = useState({
    username: "user@gmail.com",
    password: "12345678",
  });
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const { isLoading, error, field_errors } = useSelector((store) => store.user);
  const navigate = useNavigate();

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(signInUser(values));
  };

  useEffect(() => {
    if (error || field_errors) {
      if (field_errors) setErrors(field_errors);
      if (error) toast.error(error);
      dispatch(clearFieldErrors());
    }
  }, [error, field_errors]);

  return (
    <>
      <Loader loading={isLoading} />

      <div className="login-page">
        <div className="bottom-cont ">
          <img
            src={require("../../assets/logo.png")}
            alt=""
            onClick={() => navigate("/")}
            className="bonus_logo"
          />
          <p className="form-heading text-center">Sign in to your account</p>
          <form onSubmit={formSubmitHandler}>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.username ? " error" : ""}`}
                name="username"
                label="Username or Email"
                type="text"
                setValues={setValues}
                values={values}
                onChange={inputChangeHandler}
                required
              />
              {errors?.username?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <TextField
              className={` ${errors?.password ? " error" : ""}`}
              name="password"
              label="Password"
              type="password"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
            />
            <div className="flex justify-center mt-20 pt-20">
              <Link to="/forgot-password" className="mr-5 text-light">
                Forgot Password?
              </Link>
            </div>
            <button className="btn btn-primary">Sign in</button>
          </form>
          <div className=" sign-up-cont ">
            <div className=" p-cont flex align-center justify-center">
              <p className="mr-5 text-light">Don't Have Account?</p>
              <Link className="f-15 w-600" to={`/register`}>
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
