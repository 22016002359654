export const INFO = "INFO";
export const FANTACY = "FANTACY";
export const COMMENTARY = "COMMENTARY";
export const LIVE = "LIVE";
export const SCORECARD = "SCORECARD";
export const POINTS_TABLE = "POINTS_TABLE";


export const matchDetailsTabs = [
    {
        label: "Info",
        key: INFO,
        isActive: true
    },
    // {
    //     label: "Fantasy",
    //     key: FANTACY
    // },
    // {
    //     label: "Commentary",
    //     key: COMMENTARY
    // },
    {
        label: "Live",
        key: LIVE
    },
    {
        label: "Scorecard",
        key: SCORECARD
    },
    {
        label: "Points Table",
        key: POINTS_TABLE
    },
]