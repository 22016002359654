import React from "react";
import "./Umpires.scss";
import Item from "./Item";

const Umpires = ({data}) => {
    console.log(data,'some data')
  return (
    <div className="umpires">
      <Item label="Umpires" values={data?.umpires} />
      {data?.referee && <Item label="Referee" values={[data?.referee || ""]} />}
    </div>
  );
};

export default Umpires;
