import { Route } from "react-router-dom";
import AllBlogsPage from "../pages/AllBlogsPage/AllBlogsPage";
import CasinoBrandListingPage from "../pages/CasinoBrandListingPage/CasinoBrandListingPage";
import Notifications from "../pages/Notifications/Notifications";
import SharePage from "../pages/SharePage/SharePage";
import Post from "../pages/Post/Post";
import BlogDetailPage from "../pages/BlogsPage/BlogDetailPage";
import HomePage from "../pages/homepage/HomePage";
import Matches from "../pages/Matches/Matches";
import MatchDetailPage from "../pages/Matches/MatchDetaiPage/MatchDetailPage";

export const PublicRoutes = () => {
  return [
    <Route path="/matches" element={<Matches />} />,
    <Route path="/match-info/:id" element={<MatchDetailPage />} />,
    <Route path="/" element={<HomePage />} />,
    <Route path="/share/:id" element={<SharePage />} />,
    <Route path="/view-post/:id" element={<Post />} />,
    <Route path="/blogs" element={<AllBlogsPage />} />,
    <Route path="/blogs/:id" element={<BlogDetailPage />} />,
    <Route path="/casino-brands" element={<CasinoBrandListingPage />} />,
    <Route path="/notifications" element={<Notifications />} />,
  ];
};
