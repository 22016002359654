import React from "react";
import { useNavigate } from "react-router-dom";

const FinishedPage = ({
  id,
  label,
  match_1_info,
  match_2_info,
  start_at,
  data,
}) => {
  const navigate = useNavigate()
  const matchClickHandler = () => {
    navigate(`/match-info/${id}`);
  };
  return (
    <div onClick={matchClickHandler} className="match_card">
      <p className="match_title">{label}</p>
      <div className="teams_start_at_cont">
        <div className="left_cont">
          <div className="match_info match_1_info">
            <div className="logo">
              <img src={match_1_info?.logo} alt="" />
            </div>
            <p className="team_name">{match_1_info?.short_name}</p>
          </div>
          <div className="match_info match_2_info">
            <div className="logo">
              <img src={match_2_info?.logo} alt="" />
            </div>
            <p className="team_name">{match_2_info?.short_name}</p>
          </div>
        </div>
        <div className="starts_at_cont">
          <p className="Won_message">{data?.result}</p>
        </div>
      </div>
    </div>
  );
};

export default FinishedPage;
