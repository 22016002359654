import React from "react";
import Header from "./Header";
import CategoriesCont from "./CategoriesCont";
import BlogCard from "./BlogCard/BlogCard";
import "./BlogsPage.css";
import { RECOMMEND_TYPES } from "./data";
import parse from "html-react-parser";
import BlogRecommendButton from "./BlogRecommendButton";
import StickyWrapper from "../../Components/Common/StickyWrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBlogError,
  clearDetailBlog,
  fetchBlog,
} from "../../redux/blogsSlice";
import { useParams } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import UseApiError from "../../Hooks/Common/UseApiError";

const BlogDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    detail: ViewPostData,
    isLoading,
    error,
  } = useSelector((store) => store.blogs);

  UseApiError(error, clearBlogError);

  useEffect(() => {
    dispatch(fetchBlog(id));
   
  }, [id]);

  return (
    <div id="page" className="blogs_page">
      <Loader loading={isLoading} />
      <StickyWrapper>
        <Header />
        <CategoriesCont />
      </StickyWrapper>
      <div className="p-10">
        <BlogCard
          id={ViewPostData?.post?.id}
          banner_image={
            ViewPostData?.post?.image || ViewPostData?.post?.filename
          }
          postedByImage={ViewPostData?.post?.user?.profile_image}
          posted_by_name={ViewPostData?.post?.user?.name}
          posted_at={ViewPostData?.post?.updated_at}
          data={ViewPostData}
          title={ViewPostData?.post?.title}
          isBlogDetail
        />
        <div className="cols-2">
          {ViewPostData?.trending_categories?.map((each) => (
            <BlogRecommendButton
              id={each.id}
              title={each.name}
              type={RECOMMEND_TYPES?.CATEGORY}
            />
          ))}
        </div>

        <div className="html_string mt-20">
          {parse(ViewPostData?.post?.html || "")}
        </div>
        <div className="cols-2 mt-20">
          {ViewPostData?.related_articles?.map((each) => (
            <BlogRecommendButton
              id={each.id}
              title={each.title}
              type={RECOMMEND_TYPES?.BLOG}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogDetailPage;
