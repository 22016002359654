import React, { useEffect, useState } from "react";
import "./MatchDetailPage.scss";
import { useNavigate, useParams } from "react-router-dom";
import GoBackHeader from "./GoBackHeader/GoBackHeader";
import {
  COMMENTARY,
  FANTACY,
  INFO,
  LIVE,
  POINTS_TABLE,
  SCORECARD,
  matchDetailsTabs,
} from "./data";
import Tabs from "../Tabs/Tabs";
import InfoPage from "./InfoPage/InfoPage";
import FantacyPage from "./FantacyPage/FantacyPage";
import LivePage from "./LivePage/LivePage";
import ScoreCardPage from "./ScoreCardPage/ScoreCardPage";
import PointsTablePage from "./PointsTablePage/PointsTablePage";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllmatchDetails,
  selectMatchDetails,
  slectInfoLoader,
} from "../../../redux/matchDetailSlice";
import Loader from "../../../Components/Loader/Loader";
import HeaderScoreInfo from "./HeaderScoreInfo/HeaderScoreInfo";

const MatchDetailPage = () => {
  const [activeTab, setActiveTab] = useState(matchDetailsTabs[0]);
  const { id } = useParams();
  const tabClickHandler = (key) => {
    setActiveTab(key);
    console.log(key);
  };
  const matchInfo = useSelector(selectMatchDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    if (matchInfo?.info?.match_id && matchInfo?.info?.match_id != id) {
      dispatch(clearAllmatchDetails());
    }
  }, [id]);
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate("/matches");
  };
  const isLoading = useSelector(slectInfoLoader);

  return (
    <div className="match_detail_page page">
      {isLoading && <Loader />}

      <div className="detail_page_header">
        <GoBackHeader
          title={`${matchInfo?.data?.abbr || ""} ${
            matchInfo?.data?.matdch_no || ""
          }`}
          goBackHandler={goBackHandler}
        />
        <div className="sticky">
          <Tabs
            activeTab={activeTab}
            tabClickHandler={tabClickHandler}
            tabsData={matchDetailsTabs}
          />
          <HeaderScoreInfo />
        </div>
      </div>
      <div className="page_section">
        {activeTab?.key == INFO && <InfoPage />}
        {activeTab?.key == FANTACY && <FantacyPage />}
        {/* {activeTab.key == COMMENTARY && <comment />} */}
        {activeTab?.key == LIVE && <LivePage />}
        {activeTab?.key == SCORECARD && <ScoreCardPage />}
        {activeTab?.key == POINTS_TABLE && <PointsTablePage />}
      </div>
    </div>
  );
};

export default MatchDetailPage;
