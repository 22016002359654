import React, { useState } from "react";
import "./Breadcrumb.scss";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import LiveStatus from "../LiveStatus/LiveStatus";

const Breadcrumb = ({
  defaultOpen,
  children,
  title,
  series_logo,
  rightJsx,
}) => {
  const [isOpen, setIsOpen] = useState(!!defaultOpen);
  const toggleIsOpen = () => {
    setIsOpen((state) => !state);
  };
  return (
    <div className={`breadcrump ${isOpen && "open"}`}>
      <div className="header">
        <div className="left">
          {series_logo && (
            <div className="logo">
              <img src={series_logo} alt="" />
            </div>
          )}
          <p className="title">{title}</p>
        </div>
        <div className="right">
          {rightJsx ? rightJsx : !isOpen && <LiveStatus />}
          <div onClick={toggleIsOpen} className="open_status_icon">
            {!isOpen ? <BiChevronDown /> : <BiChevronUp />}
          </div>
        </div>
      </div>
      <div className="content_body">{children}</div>
    </div>
  );
};

export default Breadcrumb;
