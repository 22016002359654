import React from "react";
import UpcomingMatch from "./UpcomingMatch";
import "./UpcomingGroup.scss";
import { formatHourAndMinutes } from "../../../../utils/utils";
import FinishedPage from "./FinishedMatch";

const UpcomingGroup = ({ title, matches, type = "upcoming" }) => {
  return (
    <div className="upcoming_group">
      <p className="group_title">{title}</p>
      {matches?.map((each) =>
        type == "upcoming" ? (
          <UpcomingMatch
            key={each?.match_id}
            id={each?.match_id}
            is_live
            label={`${each?.subtitle} ${each?.format_str} , ${each?.competition?.title} ${each?.competition?.season}`}
            eventStatus={each?.status_note}
            match_1_info={{
              logo: each?.teama?.logo_url,
              short_name: each?.teama?.name,
              is_batting: false,
              score_wickets: each?.teama?.scores,
              overs: each?.teama?.overs,
            }}
            match_2_info={{
              logo: each?.teamb?.logo_url,
              short_name: each?.teamb?.name,
              is_batting: false,
              score_wickets: each?.teamb?.scores,
              overs: each?.teamb?.overs,
            }}
            start_at={formatHourAndMinutes(each?.date_start) || ""}
          />
        ) : (
          <FinishedPage
            data={each}
            key={each?.match_id}
            id={each?.match_id}
            is_live
            label={`${each?.subtitle} ${each?.format_str} , ${each?.competition?.title} ${each?.competition?.season}`}
            eventStatus={each?.status_note}
            match_1_info={{
              logo: each?.teama?.logo_url,
              short_name: each?.teama?.name,
              is_batting: false,
              score_wickets: each?.teama?.scores,
              overs: each?.teama?.overs,
            }}
            match_2_info={{
              logo: each?.teamb?.logo_url,
              short_name: each?.teamb?.name,
              is_batting: false,
              score_wickets: each?.teamb?.scores,
              overs: each?.teamb?.overs,
            }}
          />
        )
      )}
    </div>
  );
};

export default UpcomingGroup;
