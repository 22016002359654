import React, { useEffect } from "react";
import Match from "./Components/Match/Match";
import Breadcrumb from "./Components/Breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLiveMatches,
  selectLiveData,
  selectMatchesLoader,
} from "../../redux/MatchesSlice";
import Loader from "../../Components/Loader/Loader";

const LivePage = () => {
  const seriesData = useSelector(selectLiveData);
  const isLoading = useSelector(selectMatchesLoader);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLiveMatches());
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {seriesData?.map((each) => (
        <Breadcrumb
          defaultOpen={true}
          series_logo={each?.series_logo}
          title={each?.league_name}
          key={each?.id}
        >
          {each?.matches?.map((each) => (
            <Match
              key={each?.match_id}
              id={each?.match_id}
              is_live
              label={each?.title}
              eventStatus={each?.status_note}
              match_1_info={{
                logo: each?.teama?.logo_url,
                short_name: each?.teama?.short_name,
                is_batting: false,
                score_wickets: each?.teama?.scores,
                overs: each?.teama?.overs,
              }}
              match_2_info={{
                logo: each?.teamb?.logo_url,
                short_name: each?.teamb?.short_name,
                is_batting: false,
                score_wickets: each?.teamb?.scores,
                overs: each?.teamb?.overs,
              }}
            />
          ))}
        </Breadcrumb>
      ))}
    </>
  );
};

export default LivePage;
