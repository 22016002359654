import React from "react";
import "./Match.scss";
import { useNavigate } from "react-router-dom";
import LiveStatus from "../LiveStatus/LiveStatus";

const Match = ({
  id,
  label,
  is_live,
  match_1_info,
  match_2_info,
  eventStatus,
}) => {
  const navigate = useNavigate();
  const matchClickHandler = () => {
    navigate(`/match-info/${id}`);
  };
  return (
    <div onClick={matchClickHandler} className="match_card">
      <p className="match_title">{label}</p>

      <div className="flex align-center">
        <div className="left_cont flex-1">
          <div className="match_info match_1_info">
            <div className="logo">
              <img src={match_1_info?.logo} alt="" />
            </div>
            <p className="team_name">{match_1_info?.short_name}</p>
            {match_1_info?.score_wickets ? (
              <>
                <p className="score_wickets_cont">
                  {`${match_1_info?.score_wickets}`}
                </p>
                <p className="overs">{match_1_info?.overs}</p>
              </>
            ) : (
              <p className="yet_to_bet">Yet to bat</p>
            )}
            {match_1_info?.is_batting && (
              <div className="bat_icon">
                <img src={require("./cricket_bat.png")} alt="" />
              </div>
            )}
          </div>
          <div className="match_info match_2_info">
            <div className="logo">
              <img src={match_2_info?.logo} alt="" />
            </div>
            <p className="team_name">{match_2_info?.short_name}</p>
            {match_2_info?.score_wickets ? (
              <>
                <p className="score_wickets_cont">
                  {`${match_2_info?.score_wickets}`}
                </p>
                <p className="overs">{match_2_info?.overs}</p>
              </>
            ) : (
              <p className="yet_to_bet">Yet to bat</p>
            )}
            {match_2_info?.is_batting && (
              <div className="bat_icon">
                <img src={require("./cricket_bat.png")} alt="" />
              </div>
            )}
          </div>
        </div>
        <div className="starts_at_cont">
          <LiveStatus />
        </div>
      </div>

      <p className="opt_to_bat">{eventStatus && eventStatus}</p>
    </div>
  );
};

export default Match;
