import React from "react";
import "./Header.scss";
import { BiSearch } from "react-icons/bi";
import { assets } from "../../../../assets/assets";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";

const Header = () => {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate("/");
  };

  return (
    <div className="matches_header">
      <div className="logo">
        <img src={assets.images.logo} alt="" />
      </div>
      <div onClick={goToHome} className="search">
        <IoClose />
      </div>
    </div>
  );
};

export default Header;
