import { IoIosArrowRoundDown } from "react-icons/io"

export const liveScore_data = {
    overs: [
        {
            over: 42,
            score: ['2', '4', 1, 1, "W", 2, "wd", '6']
        },
        {
            over: 43,
            score: ['2', '4', 1, 1, "W", 2, "wd", '6']
        },

    ],
}

export const batterColumns = [
    {
        header: "Batter",
        accessor: "name",
        type: 'flex-1 bold align-center  pl-10'
    },
    {
        header: "R",
        accessor: "runs",
        type: "bold"
    },
    {
        header: "B",
        accessor: "balls_faced"
    },
    {
        header: "4s",
        accessor: "fours"
    },
    {
        header: "6s",
        accessor: "sixes"
    },
    {
        header: "SR",
        accessor: "strike_rate",
        type: 'pr-10'
    },
]


export const bowlerColumns = [
    {
        header: "Bowler",
        accessor: "name",
        type: 'flex-1 bold align-center  pl-10'
    },
    {
        header: "W",
        accessor: "wickets",
        type: "bold"
    },
    {
        header: "R",
        accessor: "runs"
    },
    {
        header: "Overs",
        accessor: "overs"
    },
    {
        header: "Econ",
        accessor: "Econ"
    },

]