import React, { useEffect } from "react";
import "./PointsTablePage.scss";
import Table from "../ScoreCardPage/Table";
import { pointsTableData, pointsTablecolumns } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchPointsTable,
  selectMatchDetails,
  selectPointsTableData,
} from "../../../../redux/matchDetailSlice";
import NoDataFound from "../../../../Components/NoDataFound/NoDataFound";

const PointsTablePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchPointsTable(id));
  }, []);
  const data = useSelector(selectPointsTableData);
  const match_details = useSelector(selectMatchDetails);

  console.log(data,'points table data')
  return (
    <div>
      <div className="point_table_header">
        <p className="section_heading">Points Table</p>
        {/* <div className="checkbox_cont">
          <p className="small-text">Team Form</p>
          <input type="checkbox" />
        </div> */}
      </div>
      <div className=" points_table">
        {match_details?.is_points_table_no_data ? (
          <NoDataFound />
        ) : (
          <Table data={data?.details} columns={pointsTablecolumns} />
        )}
      </div>
    </div>
  );
};

export default PointsTablePage;
